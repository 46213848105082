#block-system-main + *,
#block-views-block-list-news-event-block + *,
#block-views-block-list-news-event-block-1 + * {
	margin-top: 40px;
}

#block-enpc-blocks-youtube-channel + * {
	@include breakpoint(medium up) {
		margin-top: 40px;
	}
}