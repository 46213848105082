main.contentpage section.section .view-teachers-list {
	& > * + * {
		margin-top: 40px;
	}

	h3 + * {
		margin-top: 20px;
	}

	.view-header {
		padding: 20px;
		position: relative;

		ul {
			list-style: none;
			margin-bottom: 0;

			& > li {
				margin-bottom: 0;
				margin-left: 0;

				.description {
					padding-bottom: calc(20px + 2.4rem);

					a {
						font-family: "Helvetica Condensed", Arial, sans-serif;
						font-size: 1.8rem;
						line-height: 2.4rem;
					}

					a {
						position: absolute;
						bottom: 20px;
						left: 20px;
						color: $primary-color;

						&:after {
							margin-left: 5px;
							vertical-align: middle;
							font-family: enpc;
							content: "\69";
						}

						&:hover {
							color: darken($primary-color, 10%);
						}
					}
				}
			}
		}
	}

	.teacher-search-form {
		padding: 20px;
		background-color: $primary-color;

		h3 {
			color: $white;
		}

		.attachment {
			margin-top: 15px;
			margin-bottom: 15px;

			ul {
				list-style: none;
				margin-right: -5px;
				margin-bottom: 0;
				margin-left: -5px;
				font-size: 0;

				& > li {
					display: inline-block;
					vertical-align: middle;

					margin: 5px;

					a, span {
						display: block;
						width: 38px;
						height: 38px;
						line-height: 38px;

						text-align: center;
						font-size: 1.2rem;
						color: $black;
					}

					a {
						background-color: $white;

						&:hover, &.active {
							color: $white;
							background-color: lighten($primary-color, 10%);
						}
					}

					span {
						background-color: $darker-light-gray;
						cursor: not-allowed;
					}
				}
			}
		}
	}

	.view-filters {
		form#views-exposed-form-teachers-list-page {
			
			.form-fields {
				width: 100%;
				&:before, &:after {
					content: "";
					clear: both;
					height: 0;
					display: block;
				}

				.form-item {
					margin-top: 0;
					margin-bottom: 0;
					width: calc(50% - 10px);
					float: left;
					&:nth-child(odd) {
						margin-right: 20px;
					}
					@media screen and (max-width: 640px) {
						width: 100%;
						margin-right: 0;
						margin-bottom: 15px;
					}
				}
			}

			input.form-submit {
				display: none;
			}

			position: relative;
			p.reset-container {
				text-align: right;
				margin-top: 15px;
				a.reset {

					font-family: "Helvetica Condensed", Arial, sans-serif;
					font-size: 1.8rem;
					line-height: 2.4rem;
					color: $white;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.view-teacher-list {
		h3 {
			color: $black;
		}

		.item-list > ul {
			list-style: none;

			& > li {
				margin-bottom: 0;
				margin-left: 0;

				& + li {
					margin-top: 20px;
				}

				span.title {
					display: block;
					padding-bottom: 5px;
					border-bottom: 1px solid $light-gray;
					margin-bottom: 5px;

					font-size: 3rem;
					line-height: 3.2rem;
					color: $primary-color;
				}

				ul {
					padding-left: 5px;
					margin-bottom: 0;

					& > li {
						margin-bottom: 0;

						&:before, &:after {
							display: none;
						}

						& + li {
							margin-top: 5px;
						}

						& > div, & > div > span, & > div > span > a {
							display: inline-block;
							vertical-align: middle;
						}

						a {
							font-family: "Helvetica Condensed", Arial, sans-serif;
							font-size: 1.8rem;
							line-height: 2.4rem;
							color: $black;

							&:hover {
								font-weight: 600;
							}
						}
					}
				}
			}
		}
	}
}