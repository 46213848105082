#block-social-media-list-social-media-tl-block, #block-social-media-list-social-media-tl-block-1, #block-social-media-list-social-media-tl-block-2 {
    @include breakpoint(medium down) {
        margin-top: 40px;
    }

    .content {
        overflow: auto;
        border: none;

        .tabs-panel {
            padding: 0;
        }

        .twitt-list, .post-list {
            .one-twitt, .one-post {
                & + .one-twitt, & + .one-post {
                    border-top: 1px solid $light-gray;
                }

                @include breakpoint(medium up) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    padding-left: 100px;
                }

                @include breakpoint(medium only) {
                    &:nth-child(n+5){
                        display: none;
                    }
                }

                @include breakpoint(small down) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 60px;

                    &:nth-child(n+3){
                        display: none;
                    }
                }

                position: relative;

                .logo, .fb-icon {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    
                    &:before {
                        display: block;
                        font-family: "enpc";
                        content: "\6f";

                        @include breakpoint(medium up) {
                            font-size: 6rem;
                            line-height: 6.2rem;
                        }

                        @include breakpoint(small down) {
                            font-size: 4rem;
                            line-height: 4.2rem;
                        }
                    }

                    img {
                        display: none;
                    }
                }

                .user, .from {
                    a {
                        display: inline-block;

                        @include breakpoint(medium up) {
                            font-size: 2rem;
                            line-height: 3rem;
                        }

                        @include breakpoint(small down) {
                            font-size: 1.8rem;
                            line-height: 2rem;
                        }

                        font-weight: 700;
                        color: $black;
                    }
                }

                .twitt, .msg {
                    line-height: 2rem;
                    font-size: 1.4rem;
                }

                .twitt > a {
                    color: $twitter;
                }

                .msg > a {
                    color: $facebook;
                }
            }
        }
    }

    .social-links {
        margin-top: 20px;

        ul.tabs {
            border: none;

            li {
                display: inline-block;
                vertical-align: middle;

                & + li {
                    margin-left: 10px;
                }

                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    padding: 0;

                    border-radius: 40px;
                    background: $light-gray;

                    text-align: center;
                    color: $black;

                    &:before {
                        line-height: 40px;
                        font-size: 1.6rem;
                    }

                    &.icon-twitter[aria-selected='true'], &.icon-twitter:hover {
                        background: $twitter;
                        color: $white;
                    }

                    &.icon-facebook[aria-selected='true'], &.icon-facebook:hover {
                        background: $facebook;
                        color: $white;
                    }

                    &.icon-linkedin:hover {
                        background: $linkedin;
                        color: $white;
                    }
                }
            }
        }
    }
}

#block-social-media-list-social-media-tl-block-1 {
    @include breakpoint(small down) {
        &.row {
            margin-right: 0;
            margin-left: 0;

            & > .columns {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .content {
        max-height: 392px;
    }
}