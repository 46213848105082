ul.tabs.primary {
	background: none;
	margin: 0 0 20px 0;
	font-size: 0;

	& > li {
		display: inline-block;
		vertical-align: middle;
		margin-bottom: -2px;

		& > a {
			display: block;
			padding: 10px 20px;
			font-size: 1.8rem;

			&:hover, &:focus {
				color: $white;
			}
		}
	}
}

form#user-register-form, form#user-login, form#user-pass, form#user-profile-form, form#ask-for-translation-form {
	margin-top: 40px;

	.form-item {
		margin-top: 0;
		margin-bottom: 0;

		& + * {
			margin-top: 40px;
		}
	}

	.form-actions {
		margin-top: 40px;
		margin-bottom: 0;
	}

	.password-parent {
		width: auto;
	}

	label {
		margin-bottom: 10px;

		font-size: 2rem;
		line-height: 2.2rem;

		text-transform: uppercase;
		font-weight: 600;
	}

	input[type=text], input[type=password] {
		margin-bottom: 0;
		text-transform: none;
		border: 2px solid $light-gray;

		@include breakpoint(medium up) {
			max-width: 450px;
		}
	}

	input[type=submit] {
		@include breakpoint(medium up) {
			max-width: 450px;
		}
	}

	p {
		margin-bottom: 0;

		& + p {
			margin-top: 10px;
		}

		@include breakpoint(medium up) {
			font-size: 1.8rem;
		}

		@include breakpoint(small down) {
			font-size: 1.4rem;
		}
	}

	.description {
		margin-top: 10px;
		color: $medium-gray;

		@include breakpoint(medium up) {
			font-size: 1.8rem;
		}

		@include breakpoint(small down) {
			font-size: 1.4rem;
		}
	}
}

form#user-profile-form {
	.form-item {
		&.password-parent, &.confirm-parent {
			position: relative;
			width: auto;
			clear: none;

			@include breakpoint(small down) {
				label {
					padding-bottom: 25px;
				}
			}
		}

		&.password-parent > .password-strength {
			float: none;
			margin-top: 0;

			position: absolute;

			width: 100%;

			font-family: "Helvetica Condensed", Arial, sans-serif;
			font-size: 1.2rem;
			line-height: 1.8rem;

			@include breakpoint(medium up) {
				top: 0;
				left: 140px;

				max-width: 310px;
			}

			@include breakpoint(small down) {
				top: 25px;
				left: 0;
			}
		}

		&.confirm-parent > div.password-confirm {
			float: none;
			margin-top: 0;

			position: absolute;
			

			display: inline-block;
			width: auto;

			font-size: 1.2rem;
			line-height: 2.2rem;

			@include breakpoint(medium up) {
				top: 0;
				left: 260px;
			}

			@include breakpoint(small down) {
				top: 25px;
				left: 0;

				visibility: visible;
			}
			
			span.error, span.ok {
				text-transform: capitalize;
				font-weight: bold;
				color: $black;
			}
		}
	}

	.password-suggestions {
		width: auto;
		padding: 20px;
		margin-bottom: 0;
		border: 2px solid $light-gray;

		@include breakpoint(medium up) {
			max-width: 450px;
		}

		ul {
			padding-left: 20px;
			margin-left: 0;
		}
	}

	fieldset {
		display: none !important;
	}
}
form#ask-for-translation-form {
    p {
        font-family: "Helvetica Condensed", Arial, sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-top:20px;
        margin-bottom:20px;
    }
    
    .button input{
        float:left;
        margin:20px 20px 20px 0;
    }
}