.master-home {
	.master-head {
		@include breakpoint(medium only) {
			padding-top: 93px;
		}

		@include breakpoint(small down) {
			margin-right: -20px;
			margin-left: -20px;
		}

		.image-head {
			width: 100%;
			position: relative;

			@include breakpoint(medium up) {
				height: 450px;
			}

			@include breakpoint(small down) {
				padding-right: 20px;
				padding-bottom: 40px;
				padding-left: 20px;

				& > * {
					position: relative;
					z-index: 11;
				}
			}

			.data {
				@include breakpoint(small down) {
					display: flex;
					flex-wrap: wrap;

					font-size: 0;
				}

				& > * {
					font-family: "Helvetica Condensed", Arial, sans-serif;
					text-transform: uppercase;

					@include breakpoint(medium up) {
						position: absolute;
						z-index: 11;

						width: 33.3333%;
						padding: 20px 30px;
					}

					@include breakpoint(small down) {
						width: 50%;
						padding: 20px 15px;
					}
				}

				.data-1, .data-2 {
					bottom: 100%;
				}

				.data-1 {
					right: 33.3333%;

					background-color: $primary-color;
					color: $white;
				}

				.data-2 {
					background-color: $white;
				}

				.data-2, .data-3, .data-4 {
					right: 0;
				}

				.data-3 {
					top: 0;

					background-color: $white;
				}

				.data-4 {
					background-color: $primary-color;
					color: $white;
				}

				.number {
					font-weight: 700;

					@include breakpoint(medium up) {
						font-size: 3.6rem;
						line-height: 3.6rem;
					}

					@include breakpoint(small down) {
						font-size: 2.6rem;
						line-height: 2.6rem;
					}
				}

				.text {
					margin-top: 5px;
					font-weight: 300;

					@include breakpoint(medium up) {
						font-size: 1.2rem;
						line-height: 1.2rem;
					}

					@include breakpoint(small down) {
						font-size: 1rem;
						line-height: 1.2rem;
					}
				}
			}

			.next-event {
				@include breakpoint(medium up) {
					position: absolute;
					right: 16.6666%;
					bottom: 16.6666%;
					z-index: 11;

					width: 300px;
				}

				@include breakpoint(small down) {
					margin-top: 80px;
				}

				h4 {
					margin-bottom: 0;
					font-weight: 600;
					color: $white;

					@include breakpoint(medium up) {
						font-size: 3rem;
						line-height: 3.2rem;

						text-align: justify;
					}

					@include breakpoint(small down) {
						font-size: 2.4rem;
						line-height: 2.6rem;
					}
				}

				p {
					margin-top: 15px;
					font-family: "Helvetica Condensed", Arial, sans-serif;
					font-size: 1.8rem;
					color: $white;

					@include breakpoint(medium up) {
						text-align: justify;
					}
				}

				a.suscribe {
					display: block;
					height: 60px;
					line-height: 60px;			

					background-color: $white;

					font-family: "Helvetica Condensed", Arial, sans-serif;
					text-transform: uppercase;
					text-align: center;
					font-size: 1.5rem;
					color: $black;

					@include breakpoint(medium up) {
						margin-top: 20px;
					}

					@include breakpoint(small down) {
						margin-top: 80px;
					}

					transition: background-color 0.2s ease-in-out;

					&:hover {
						background-color: darken($white, 10%);
					}
				}
			}
		}

		.fix-position-bottom {
			@include breakpoint(medium up) {
				padding-bottom: 30px;
				position: relative;
			}

			.description {
				background-color: #eaebec;

				@include breakpoint(medium up) {
					padding: 30px 30px 60px 30px;
				}

				@include breakpoint(small down) {
					padding: 40px 20px;
				}

				h3.title {
					color: $black;
				}

				.links {
					@include breakpoint(medium up) {
						position: absolute;
						right: 30px;
						bottom: 0;
						left: 30px;
					}

					font-size: 0;

					& > a {
						@include breakpoint(medium up) {
							display: inline-block;
							vertical-align: middle;
							max-width: 260px;

							& + a {
								margin-left: 1px;
							}
						}

						@include breakpoint(small down) {
							display: block;

							& + a {
								margin-top: 1px;
							}
						}

						width: 100%;
						height: 60px;
						line-height: 60px;

						background-color: $dark-gray;

						font-family: "Helvetica Condensed", Arial, sans-serif;
						font-size: 1.5rem;
						font-weight: 700;
						text-transform: uppercase;
						text-align: center;
						color: $white;

						transition: background-color 0.2s ease-in-out;

						&:hover {
							background-color: darken($dark-gray, 10%);
						}
					}
				}
			}
		}
	}
}