*, *:before, *:after {
	box-sizing: border-box;

	/* make transparent link selection, adjust last value opacity 0 to 1.0 */
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-overflow-scrolling: touch;
}

html {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: auto;
}

body {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	&.no-scroll {
		overflow: hidden;
	}
}

h1, h2, h3, h4 {
	color: $black;
}

h1, h2 {
	margin-bottom: 20px;
	
	text-transform: uppercase;
	font-weight: 600;

	@include breakpoint(medium up) {
		font-size: 5rem;
		line-height: 6rem;
	}

	@include breakpoint(small down) {
		font-size: 2.5rem;
		line-height: 3rem;
	}
}

h1 {
	@include breakpoint(medium up) {
		padding-right: 60px;
	}

	@include breakpoint(small down) {
		padding-right: 40px;
	}
}

iframe {
	border: 0;
}

.element-invisible {
	width: 1px;
	margin-bottom: 0;
	visibility: hidden;
}

#skip-link, #toolbar, #messages {
	//display: none;
}

.clearfix:after {
	content: "";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

@include breakpoint(large up) {
	.align-right {
		text-align: right;

		& > * {
			display: inline-block;
			text-align: left;
		}
	}
}

.table {
	display: table;
	height: 100%;

	.table-cell {
		display: table-cell;
		vertical-align: middle;
	}
}

.item-list {
	position: relative;

	@include breakpoint(medium up) {
		overflow: hidden;
	}
}

.slick-slider {
    position: static;

    .slick-track > .slick-slide li {
        margin: 0;
    }

	&.slider-solo {
		margin: 0;

		.slick-track > .slick-slide {
            margin: 0;
		}
	}

	&.slider-multi {
		@include breakpoint(small up) {
			margin: 0 -1.5rem;

			.slick-track > .slick-slide {
				margin: 0 1.5rem;
			}
		}

		@include breakpoint(small down) {
			margin: 0;

			.slick-track > .slick-slide {
				margin: 0;
			}
		}
	}
}

@include breakpoint(small down) {
	.row {
		.row {
			margin: 0;
		}
		
		.columns {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

.tabs {
	border: none;
}

.image {
	& > img {
		width: 100% !important;
		height: auto !important;
	}
}

.image-filter {
	position: relative;

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		background: url(../images/img-filter.png) repeat;

		content: "";
		z-index: 10;
	}

	& > img {
		width: 100% !important;
		height: auto !important;
	}
}

:focus {
	outline: none;
}

input[type="text"], input[type=password], input[type="email"], select.form-select {
	width: 100%;
	border: none;
	border-radius: 0;
	text-transform: uppercase;
	background-color: $white;

	@include breakpoint(medium up) {
		height: 80px;
		padding-right: 30px;
		padding-left: 30px;
		font-size: 2rem;
	}

	@include breakpoint(small down) {
		height: 50px;
		padding-right: 20px;
		padding-left: 20px;
		font-size: 1.4rem;
	}

	&:disabled {
		background-color: $light-gray;
	}
}

textarea {
	width: 100%;
	border: none;
	text-transform: uppercase;
	background-color: $white;

	resize: vertical;

	@include breakpoint(medium up) {
		padding: 20px 30px;
		font-size: 2rem;
	}

	@include breakpoint(small down) {
		padding: 10px 20px;
		font-size: 1.4rem;
	}

	&:disabled {
		background-color: $light-gray;
	}
}

select.form-select {
	background-image: url(../images/arrow-select.svg);
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
	background-size: 15px auto;

	appearance: none;
}

input[type="submit"].form-submit, button.submit {
	display: block;
	width: 100%;
	background-color: $primary-color;
	border: none;
	text-align: center;
	text-transform: uppercase;
	color: white;

	@include breakpoint(medium up) {
		height: 80px;
		padding-right: 20px;
		padding-left: 20px;
		font-size: 2rem;
	}

	@include breakpoint(small down) {
		height: 50px;
		padding-right: 5px;
		padding-left: 5px;
		font-size: 1.4rem;
	}

	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: darken($primary-color, 10%);
	}
}