main.contentpage section.section aside.aside {
	@include breakpoint(medium down) {
		display: none;
	}

	ul.menu {
		margin: 0;

		& > li {
			list-style: none;
			margin: 0;
			padding: 0;

			&.active > a {
				font-weight: 700;
			}

			&.active > a, & > a:hover {
				color: $dark-gray;

				&:before, &:after {
					background-color: $medium-gray;
					z-index: 10;
				}
			}

			& > a {
				display: block;
				padding-top: 16px;
				padding-bottom: 15px;

				font-family: "Helvetica Condensed", Arial, sans-serif;
				font-size: 2rem;
				line-height: 2.4rem;
				font-weight: 300;
				color: $medium-gray;

				position: relative;

				&:before {
					position: absolute;
					top: 0;
					right: 0;
					width: 100%;
					height: 1px;
					background-color: $light-gray;
					content: "";
				}

				&:after {
					position: absolute;
					bottom: -1px;
					right: 0;
					width: 100%;
					height: 1px;
					background-color: $light-gray;
					content: "";
				}
			}

			& > ul.menu {
				padding-left: 20px;

				& > li {
					&.first > a:before {
						width: calc(100% + 20px);
					}

					&.last {
						&:not(.expanded) > a:after {
							width: calc(100% + 20px);
						}

						&.expanded > ul.menu > li.last {
							&:not(.expanded) > a:after {
								width: calc(100% + 40px);
							}

							&.expanded > ul.menu > li.last {
								&:not(.expanded) > a:after {
									width: calc(100% + 60px);
								}
							}
						}
					}
				}
			}
		}
	}

	.menu-block-wrapper > ul.menu > li > a {
		text-transform: uppercase;
		font-weight: 700;
	}
}