#block-enpc-blocks-youtube-channel, #block-enpc-blocks-image-link {
	.channel {
		@include breakpoint(medium only) {
			overflow: hidden;
		}

		ul {
			list-style: none;
			margin-bottom: 0;

			@include breakpoint(medium only) {
				padding-bottom: 80px;
			}

			& > li {
				margin-bottom: 0;
				margin-left: 0;
			}

			.slick-arrow {
				position: absolute;

				@include breakpoint(medium only) {
					width: 60px;
					height: 60px;

					bottom: 0;
					background-color: $light-gray;

					&:hover {
						background-color: darken($light-gray, 10%);
					}
				}

				@include breakpoint(small down) {
					width: 30px;
					height: 30px;

					top: 50%;
					transform: translateY(-50%);
					z-index: 10;

					background-color: $dark-gray;
					color: $white;
				}

				&.slick-prev {
					@include breakpoint(medium only) {
						left: 1.5rem;
					}

					@include breakpoint(small down) {
						left: -15px;
					}

					&:before{
						font-family: "enpc";
						content: "\68";
					}
				}

				&.slick-next {
					@include breakpoint(medium only) {
						left: calc(1.5rem + 62px);
					}

					@include breakpoint(small down) {
						 right: -15px;
					}

					&:before{
						font-family: "enpc";
						content: "\69";
					}
				}
			}
		}

		a {
			display: block;

			&:hover .image:before {
				background-color: rgba($black, 0.2);
			}

			.image {
				position: relative;

				& > img {
					width: 100%;
				}

				&:before {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 10;

					background-color: rgba($black, 0.4);
					transition: background-color 0.2s ease-in-out;

					content: "";
				}

				&:after {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 12;

					font-family: "enpc";
					font-size: 4rem;
					content: "\6e";
					color: $white;
				}
			}

			h4 {
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 0;

				font-family: "Helvetica Condensed", Arial, sans-serif;
				font-weight: 700;

				font-size: 2rem;

				@include breakpoint(large up) {
					text-align: center;
				}
			}
		}
	}
}

#block-enpc-blocks-image-link {
	.channel {
		a .image:after {
			font-size: 8rem;
		}
	}
}