footer#footer {
	@include breakpoint(medium up) {
		background-color: $black;
	}

	@include breakpoint(small down) {
		background-color: $footer-mobile;
	}

	nav.inline {
		text-align: center;

		ul.menu {
			display: inline-block;
			margin: 0;

			font-size: 0;

			@include breakpoint(small down) {
				width: 100%;
			}

			& > li {
				margin: 0;
				padding: 0;

				list-style: none;

				@include breakpoint(medium up) {
					display: inline-block;
					vertical-align: middle;
				}

				& + li {
					@include breakpoint(medium up) {
						margin-left: 20px;
						padding-left: 21px;

						position: relative;

						&:before {
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);

							height: 10px;
							width: 1px;

							background-color: $medium-gray;

							content: "";
						}
					}

					@include breakpoint(small down) {
						border-top: 1px solid $black;
					}
				}

				& > a {
					font-size: 1.4rem;
					color: $medium-gray;

					&:hover {
						color: lighten($medium-gray, 20%);
					}

					@include breakpoint(small down) {
						display: block;
						height: 60px;
						line-height: 60px;
						padding-right: 20px;
						padding-left: 20px;

						text-align: center;
					}
				}
			}
		}
	}

	.menu-footer-top {		
		background-color: $footer;

		@include breakpoint(medium up) {
			padding: 80px 20px;
		}

		@include breakpoint(medium only) {
            padding-right: 20px;
            padding-left: 20px;
        }

		.row {
			position: relative;

			.image {
				@include breakpoint(small down) {
					display: none;
				}

				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);

				width: 80px;
			}

			nav {
				@include breakpoint(medium up) {
					padding-right: 80px;
					padding-left: 80px;
				}

				text-align: center;

				ul.menu {
					& > li {
						a {
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}	

	.menu-footer-bottom {
		padding-right: 20px;
		padding-bottom: 10px;
		padding-left: 20px;

		@include breakpoint(medium up) {
			padding-top: 40px;
			box-shadow: 0px 0 40px 10px rgba(black, 0.3);

			& > * + * {
				margin-top: 40px;
			}
		}

		@include breakpoint(medium down) {
            padding-right: 20px;
            padding-left: 20px;
        }

		@include breakpoint(small down) {
			padding-top: 10px;
		}

		nav.sitemap {
			& > ul.menu {
				font-size: 0;

				& > li {
					display: inline-block;
					vertical-align: top;

					width: 25%;
				}
			}

			ul.menu {
				margin-bottom: 0;
				margin-left: 0;

				& > li {
					list-style: none;

					& > * {
						color: $medium-gray;
					}

					span {
						text-transform: uppercase;
						font-size: 1.8rem;
					}

					a {
						font-size: 1.5rem;

						&:hover {
							color: lighten($medium-gray, 20%);
						}
					}
				}
			}
		}

		.tools {
			position: relative;

			.text {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);

				width: 55px;

				font-size: 1.4rem;
				color: $medium-gray;
			}

			nav {
				padding-right: 80px;
				padding-left: 80px;
			}
		}

		.item-list {
			ul {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				justify-content: space-between;

				margin: 0;

				& > li {
					margin: 0;

					a {
						display: block;

						img {
							max-width: 120px;
							max-height: 55px;
							height: auto;
							width: auto;
						}
					}
				}
			}
		}

		.copyright p {
			margin-bottom: 0;

			text-align: right;
			color: $medium-gray;

			@include breakpoint(medium up) {
				font-size: 1.4rem;
			}

			@include breakpoint(small down) {
				font-size: 1rem;
			}
		}
	}
}