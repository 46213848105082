@media print {
	html, body {
		position: static !important;

		height: auto !important;
		width: 100% !important;

		overflow-x: hidden !important;
		overflow-y: auto !important;
	}

	body {
		background: white !important;
		font-size: 12pt !important;
		color: black !important;
	}

	header#header {
		a#logo {
			& > img {
				width: 50px !important;
			}

			& > .icon-enpc {
				display: none !important;
			}
		}

		.open-menu, .action {
			display: none !important;
		}
	}

	footer#footer {
		display: none !important;
	}

	aside.aside {
		display: none !important;
	}

	#skip-link, #toolbar, #messages, .tabs {
		display: none !important;
	}

	.social-media-link {
		display: none !important;
	}

	.more-infos {
		.documents, .links {
			display: none !important;
		}
	}

	.image-filter:after {
		display: none !important;
	}

	a {
		text-decoration: none !important;

		&[href]:after {
			content: none !important;
		}

		&.print {
			display: none !important;
		}
	}

	div, article, section {
		margin: 0 !important;
		padding: 0 !important;
	}

	h1, h2, h3, h4, p, ul, ol, a, img {
		margin-top: 0 !important;
		margin-bottom: 10px !important;
	}

	img {
		max-width: 50% !important;
	}
}