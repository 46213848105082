.home-content {
	#block-enpc-blocks-make-donation {
		@include breakpoint(medium down) {
            margin-top: 40px;
        }

		.content {
			@include breakpoint(large up) {
				height: 290px;
			}
			
			padding: 40px;

			background: url(../images/bg-donation.jpg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			& > div {
				line-height: 2rem;
				font-size: 1.6rem;
				color: white;

				max-height: 124px; // A supprimer
				overflow: hidden; // A supprimer
			}

			.action {
				margin-top: 20px;

				& > * {
					display: inline-block;
					vertical-align: middle;
				}

				a {
					width: calc(100% - 77px);
					height: 60px;
					line-height: 60px;

					background-color: $donation;

					text-align: center;
					text-transform: uppercase;
					font-size: 1.5rem;
					color: white;

					&:hover {
						background-color: darken($donation, 10%);
					}
				}

				img {
					width: auto;
					height: 60px;
					margin-left: 20px;
				}
			}
		}
	}
}