#site-map {
	font-size: 1.4rem;

	.content > ul.site-map-menu > li {
		& + li {
			margin-top: 40px;
		}

		& > a {
			text-transform: uppercase;
			font-weight: 700;
		}
	}

	ul.site-map-menu {
		margin-bottom: 0;
		margin-left: 0;
		padding-left: 0;

		& > li {
			list-style: none;
			margin: 0;
			padding: 0;

			& > a:hover {
				color: $dark-gray;

				&:before, &:after {
					background-color: $medium-gray;
					z-index: 10;
				}
			}

			& > a, & > span {
				display: inline-block;
				padding-top: 10px;
				padding-bottom: 10px;

				font-family: "Helvetica Condensed", Arial, sans-serif;
				font-size: 2rem;
				line-height: 2.4rem;
				font-weight: 300;
				color: $medium-gray;
			}

			& > ul.site-map-menu {
				padding-left: 40px;
			}
		}
	}
}