main.contentpage section.section .biblio-search-school {
	& > * + * {
		@include breakpoint(medium up) {
			margin-top: 40px;
		}
	}

	ul.row, ul.slick-slider {
		list-style: none;

		li {
			a {
				display: block;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				.title {
					padding-right: 20px;
					padding-left: 20px;

					position: relative;

					h4 {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						margin-bottom: 0;

						font-size: 1.8rem;
						text-align: center;
						color: $white;
					}
				}
			}
		}
	}

	ul.row {
		margin-top: -1.5rem;
		margin-bottom: -1.5rem;

		& > li {
			margin-top: 1.5rem;
			margin-bottom: 1.5rem;
		}
	}

	.search-theme {
		ul.row {
			& > li {
				a {
					&:after {
						transition: background-color 0.2s ease-in-out;
					}

					&:hover:after {
						background-color: rgba($black, 0.2);
					}

					.title {
						height: 300px;
						z-index: 10;
					}
				}
			}
		}
	}

	.laboratory {
		@include breakpoint(small down) {
			margin-top: 40px;
		}

		ul.row {
			& > li {
				a {
					&:hover .title {
						background-color: rgba($black, 0.4);
					}

					.title {
						height: 180px;

						background-color: rgba($black, 0.6);
						transition: background-color 0.2s ease-in-out;
					}
				}
			}
		}
	}

	.chaires {
		@include breakpoint(medium up) {
			padding-bottom: 80px;
		}

		@include breakpoint(small down) {
			padding: 40px 20px;

			margin-top: 40px;
			margin-right: -20px;
			margin-left: -20px;

			background-color: $light-gray;
		}		

		.fix-button-slider {
			@include breakpoint(medium up) {
				position: relative;

				&:before, &:after {
					position: absolute;
					top: 0;
					bottom: 0;
					
					width: 2000px;
					background-color: $light-gray;

					content: "";
				}

				&:before {
					left: 100%;
				}

				&:after {
					right: 100%;
				}
			}

			ul.slick-slider {
				@include breakpoint(medium up) {
					padding-top: 20px;
					padding-bottom: 20px;

					background-color: $light-gray;
				}

				.slick-list {
					li {
						a {
							&:hover .title {
								background-color: rgba($black, 0.4);
							}

							.title {
								height: 180px;

								background-color: rgba($black, 0.6);
								transition: background-color 0.2s ease-in-out;
							}
						}
					}
				}

				.slick-arrow {
					position: absolute;

					@include breakpoint(medium up) {
						width: 60px;
						height: 60px;

						top: calc(100% + 20px);
						background-color: $light-gray;

						&:hover {
							background-color: darken($light-gray, 10%);
						}
					}

					@include breakpoint(small down) {
						width: 30px;
						height: 30px;

						top: 50%;
						transform: translateY(-50%);
						z-index: 10;

						background-color: $dark-gray;
						color: $white;
					}

					&.slick-prev {
						@include breakpoint(medium up) {
							left: 1.5rem;
						}

						@include breakpoint(small down) {
							left: -15px;
						}

						&:before{
							font-family: "enpc";
							content: "\68";
						}
					}

					&.slick-next {
						@include breakpoint(medium up) {
							left: calc(1.5rem + 62px);
						}

						@include breakpoint(small down) {
							 right: -15px;
						}

						&:before{
							font-family: "enpc";
							content: "\69";
						}
					}
				}
			}
		}
	}
}