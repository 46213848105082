form#views-exposed-form-advanced-search-page,
form#views-exposed-form-news-events-search-page {
	background-color: $light-gray;

	@include breakpoint(large up) {
		margin-top: 60px;
		padding-top: 40px;
		padding-bottom: 40px;
	}

	@include breakpoint(medium up) {
		position: relative;

		&:before, &:after {
			position: absolute;
			top: 0;
			bottom: 0;
			
			width: 2000px;
			background-color: $light-gray;

			content: "";
		}

		&:before {
			left: 100%;
		}

		&:after {
			right: 100%;
		}
	}

	@include breakpoint(medium down) {
		margin-top: 40px;
		padding: 20px;
	}

	h3 {
		text-transform: uppercase;
		font-weight: 600;

		@include breakpoint(medium up) {
			font-size: 3rem;
			line-height: 3.2rem;
		}

		@include breakpoint(small down) {
			font-size: 1.8rem;
			line-height: 2rem;
		}
	}

	a.reset {
		font-family: "Helvetica Condensed", Arial, sans-serif;
		text-decoration: underline;

		@include breakpoint(medium up) {
			font-size: 1.6rem;
		}

		@include breakpoint(small down) {
			font-size: 1.4rem;
		}
	}

	.form-fields {
		@include breakpoint(medium up) {
			margin: 40px -5px;
			font-size: 0;

			& > * {
				display: inline-block;
				vertical-align: middle;
				padding-right: 5px;
				padding-left: 5px;

				font-size: 2rem;
			}
		}

		@include breakpoint(small down) {
			margin-top: 20px;
			margin-bottom: 5px;
		}

		.form-item {
			margin-bottom: 0;

			&.form-item-body-value {
				display: none;
			}

			@include breakpoint(medium up) {
				margin-top: 0;

				&.form-item-title {
					width: 41.6666%;
				}

				&.form-item-created {
					width: 25%;
				}

				&.form-item-theme {
					width: 33.3333%;
				}
			}

			@include breakpoint(small down) {
				&.form-item-title, &.form-item-created, &.form-item-theme {
					margin-top: 5px;
				}
			}
		}

		#edit-field-publish-date-value-value-wrapper {
			@include breakpoint(medium up) {
				width: 25%;
			}

			@include breakpoint(small down) {
				margin-top: 5px;
			}

			div {
				width: 100%;
				margin-right: 0;

				input {
					margin-right: 0;
				}
			}
		}

		#edit-field-publish-date-value2-value-wrapper {
			display: none;
		}

		input.datepicker {
			width: 100%;
			padding-right: 60px;
			background: white url(../images/date.svg) no-repeat right 15px center;
			background-size: 20px auto;
		}
	}

	@include breakpoint(medium up) {
		input[type="submit"] {
			width: 280px;
			margin-left: auto;
		}
	}
}

.view-advanced-search, .view-news-events-search {
	.view-header {
		font-family: "Helvetica Condensed", Arial, sans-serif;

		@include breakpoint(medium up) {
			font-size: 1.8rem;
		}

		@include breakpoint(small down) {
			padding-right: 20px;
			padding-left: 20px;
			font-size: 1.4rem;
		}
	}

	.view-content {
		margin-bottom: 20px;

		ul {
			margin-bottom: 0;
			list-style: none;

			& > li {
				margin: 0;

				& + li {
					border-top: 1px solid $light-gray;
				}

				& > a {
					display: block;

					&:hover {
						background-color: lighten($light-gray, 5%);
					}

					& > * {
						font-family: "Helvetica Condensed", Arial, sans-serif;
					}

					.date {
						@include breakpoint(medium up) {
							font-size: 1.5rem;
						}
						
						@include breakpoint(small down) {
							line-height: 30px;
							padding-right: 40px;

							font-size: 1.2rem;
						}
					}

					h4 {
						text-transform: uppercase;
						font-weight: 700;

						@include breakpoint(medium up) {
							font-size: 2.8rem;
						}
						
						@include breakpoint(small down) {
							margin-top: 5px;
							margin-bottom: 10px;
							font-size: 1.6rem;
						}
					}

					p {
						color: $black;
						margin-bottom: 0;

						@include breakpoint(medium up) {
							font-size: 1.8rem;
						}
						
						@include breakpoint(small down) {
							font-size: 1.6rem;
							line-height: 1.8rem;
						}
					}
				}
			}
		}
	}

	ul.pager {
		margin-bottom: 0;
		text-align: left;
		font-size: 0;
		
		@include breakpoint(medium down) {
			padding-right: 20px;
			padding-left: 20px;
		}

		& > li {
			display: inline-block;
			vertical-align: middle;

			margin: 0;
			padding: 0;

			font-size: 1.5rem;

			& + li {
				margin-left: 1px;
			}

			& > a {
				text-indent: 100%;
				overflow: hidden;

				@include breakpoint(medium up) {
					width: 60px;
				}

				@include breakpoint(small down) {
					width: 50px;
				}

				position: relative;

				&:after {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;

					font-family: "enpc";
					text-align: center;
					text-indent: 0;
				}
			}

			&.pager-previous > a:after {
				content: "\68";
			}

			&.pager-next > a:after {
				content: "\69";
			}

			@include breakpoint(small down) {
				&.pager-info {
					width: calc(100% - 102px);
				}
			}

			& > span {
				@include breakpoint(medium up) {
					padding-right: 40px;
					padding-left: 40px;
				}

				@include breakpoint(small down) {
					text-align: center;
				}

				font-family: "Helvetica Condensed", Arial, sans-serif;
				text-transform: uppercase;
			}

			& > a, & > span {
				display: block;
				background-color: $light-gray;
				color: $dark-gray;

				@include breakpoint(medium up) {
					height: 60px;
					line-height: 60px;
				}

				@include breakpoint(small down) {
					height: 50px;
					line-height: 50px;
				}
			}
		}
	}
}

.view-advanced-search .view-content {
	ul > li > a {
		@include breakpoint(medium up) {
			padding: 20px 80px 20px 20px;
		}

		@include breakpoint(small down) {
			padding: 20px;
		}

		position: relative;

		&:after {
			position: absolute;
			right: 20px;

			background-color: $primary-color;

			font-family: "enpc";
			font-size: 2rem;
			text-align: center;
			color: $white;

			@include breakpoint(medium up) {
				bottom: 20px;

				width: 40px;
				height: 40px;
				line-height: 40px;
			}
			
			@include breakpoint(small down) {
				top: 20px;

				width: 30px;
				height: 30px;
				line-height: 30px;
			}
		}

		&.news:after {
			content: "\6d";
		}

		&.event:after {
			content: "\66";
		}

		&.page:after {
			content: "\77";
		}
	}
}

.view-news-events-search .view-content {
	ul > li > a {
		padding: 20px;
		font-size: 0;

		& > * {
			display: inline-block;
			vertical-align: middle;
		}

		.image {
			position: relative;

			&:after {
				position: absolute;
				right: 10px;
				bottom: 10px;

				background-color: $primary-color;

				font-family: "enpc";
				font-size: 2rem;
				text-align: center;
				color: $white;

				@include breakpoint(medium up) {
					width: 40px;
					height: 40px;
					line-height: 40px;
				}

				@include breakpoint(small down) {
					width: 30px;
					height: 30px;
					line-height: 30px;
				}
			}

			@include breakpoint(medium up) {
				width: 150px;
				height: 150px;

				background-color: $light-gray;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;

				& > img {
					display: none;
				}
			}
			
			@include breakpoint(small down) {
				background: none !important;
				width: 100%;
				min-height: 30px;
			}
		}

		&.news .image:after {
			content: "\6d";
		}

		&.event .image:after {
			content: "\66";
		}

		@include breakpoint(medium up) {
			.description {
				width: calc(100% - 150px);
				padding-left: 20px;
			}
		}
	}
}