.home-content {
	#block-views-block-list-video-block {
		li {
			a {
				display: block;
				position: relative;

				.image {
					position: relative;

					&:before {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						font-family: "enpc";
						font-size: 4rem;
						content: "\6e";
						color: $white;
					}

					img {
						width: 100% !important;
					}
				}

				.description {
					@include breakpoint(medium up) {
						margin-top: 20px;
					}

					@include breakpoint(small down) {
						padding-top: 10px;
						padding-bottom: 10px;
					}

					h4 {
						font-weight: 700;
						margin-bottom: 0;

						@include breakpoint(medium up) {
							font-size: 1.8rem;
							line-height: 1.8rem;
						}

						@include breakpoint(small down) {
							font-size: 2rem;
						}
					}

					.duration {
						font-size: 1.4rem;

						@include breakpoint(medium up) {
							line-height: 1.4rem;
							color: $dark-gray;
						}
					}
				}
			}
		}

		.navigation {
			margin-top: 20px;

			@include breakpoint(small down) {
				padding-right: 20px;
				padding-left: 20px;
			}

			& > a {
				display: inline-block;
				font-size: 1.4rem;

				&:after {
					margin-left: 5px;
					vertical-align: middle;

					font-family: "enpc";
					content: "\69";
				}
			}
		}

		.slick-arrow {
			position: absolute;
			
			z-index: 9;

			@include breakpoint(medium up) {
				width: 60px;
				height: 60px;

				top: 0;
				background-color: $light-gray;


				&:hover {
					background-color: darken($light-gray, 10%);
				}
			}

			@include breakpoint(small down) {
				width: 30px;
				height: 30px;

				top: 50%;
				transform: translateY(-50%);
				z-index: 10;

				background-color: $dark-gray;
				color: $white;
			}

			&.slick-prev {
				@include breakpoint(medium up) {
					right: 62px;
				}

				@include breakpoint(small down) {
					left: -15px;
				}

				&:before{
					font-family: "enpc";
					content: "\68";
				}
			}

			&.slick-next {
				@include breakpoint(medium up) {
					right: 0;
				}

				@include breakpoint(small down) {
					 right: -15px;
				}

				&:before{
					font-family: "enpc";
					content: "\69";
				}
			}
		}
	}
}

.reveal {
	padding: 20px;
	background: none;

	position: relative;

	.close-button {
		position: absolute;
		top: 20px;
		left: 20px;

		z-index: 10;

		span {
			font-size: 4rem;
			color: $white;
		}
	}

	.mask {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		background-color: rgba($black, 0.8);
	}

	.table {
		width: 100%;

		.title {
			text-align: center;
			color: $white;

			@include breakpoint(medium up) {
				font-size: 3rem;
			}

			@include breakpoint(small down) {
				 font-size: 2rem;
				 margin-bottom: 10px;
			}
		}

		.video-container {
			width: 100%;
			max-width: 960px;
			margin: 0 auto;

			.video {
				position: relative;
				height: 0;

				padding-bottom: 56.25%;
				overflow: hidden;

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}