.template2 {
	& + * {
		margin-top: 30px;
	}

	.image-filter img {
		width: 100% !important;
		height: auto !important;
	}
	
	.description {
		padding: 30px;
		background-color: $light-gray;

		ul {
			list-style: none;

			& > li {
				color: $black;
				
				position: relative;

				&:before {
					position: absolute;
					top: 0;
					left: -1.8rem;
					content: "•";
					color: $primary-color;
				}
			}
		}
	}
}