main.contentpage section.section .biblio-search-school {
	.website {
		@include breakpoint(small down) {
			padding: 40px 20px;
			margin-right: -20px;
			margin-left: -20px;

			background-color: $light-gray;
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: space-between;

			margin: 0;

			list-style: none;

			@include breakpoint(medium up) {
				flex-wrap: nowrap;

				padding-top: 60px;
				padding-bottom: 60px;

				background-color: $light-gray;

				position: relative;

				&:before, &:after {
					position: absolute;
					top: 0;
					bottom: 0;
					
					width: 2000px;
					background-color: $light-gray;

					content: "";
				}

				&:before {
					left: 100%;
				}

				&:after {
					right: 100%;
				}
			}

			@include breakpoint(small down) {
				flex-wrap: wrap;

				margin: -10px;
			}

			& > li {
				@include breakpoint(medium up) {
					margin: 0;
				}

				@include breakpoint(small down) {
					margin: 10px;
				}

				a {
					display: block;

					img {
						height: auto;
						width: auto;

						@include breakpoint(medium up) {
							max-width: 250px;
							max-height: 100px;
						}

						@include breakpoint(small down) {
							max-width: 180px;
							max-height: 80px;
						}
					}
				}
			}
		}
	}
}
