.template5 {
	& + * {
		margin-top: 30px;
	}

	@include breakpoint(medium up) {
		height: 680px;
	}

	@include breakpoint(small down) {
		.row .column {
			
		}
	}

	a {
		display: block;
	}

	.image > img {
		width: 100% !important;
		height: auto !important;
	}

	.description {
		h4 {
			font-family: "Helvetica Condensed", Arial, sans-serif;
			font-weight: 700;
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
	}
}