.home-header {
	#block-enpc-blocks-direct-access {
		padding-right: 20px;
		padding-left: 20px;

		background-position: center;
		background-size: cover;

		.table {
			width: 100%;
			height: 600px;

			position: relative;
			z-index: 15;
		}

		h1, span.subtitle {
			margin-bottom: 0;
			color: $white;
		}

		span.subtitle {
			display: block;
			font-weight: 300;

			@include breakpoint(medium up) {
				margin-top: 20px;

				font-size: 5rem;
				line-height: 6rem;
			}

			@include breakpoint(small down) {
				margin-top: 40px;

				font-size: 2.6rem;
				line-height: 2.6rem;
			}
		}

		#fast-link-content {
			margin-top: 60px;

			& > * {
				@include breakpoint(medium up) {
					display: inline-block;
					vertical-align: middle;
					height: 80px;

					& + * {
						margin-left: 1px;
					}
				}

				@include breakpoint(small down) {
					display: block;
					width: 100%;
					height: 60px;

					& + * {
						margin-top: 5px;
					}
				}
			}

			select {
				@include breakpoint(medium up) {
					max-width: calc(50% - 126px);
				}
			}

			button {
				@include breakpoint(medium up) {
					max-width: 250px;
				}
			}
		}
	}
}