.master-home {
	.list-master {
		@include breakpoint(small down) {
			padding-top: 40px;
			padding-bottom: 40px;
			margin-right: -20px;
			margin-left: -20px;

			position: relative;

			h2 {
				color: $white;
				padding-right: 20px;
				padding-left: 20px;
			}
		}

		ul.row {
			list-style: none;
			margin-top: -1.5rem;
			margin-bottom: -1.5rem;

			& > li {
				margin-top: 1.5rem;
				margin-bottom: 1.5rem;

				& > * {
					display: block;
					height: 180px;

					font-family: "Helvetica Condensed", Arial, sans-serif;
					color: $white;
				}

				.title {
					padding-right: 20px;
					padding-left: 20px;
					position: relative;

					h4, h5 {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						margin-bottom: 0;
						
						text-align: center;
					}

					h4 {
						text-transform: uppercase;
						font-size: 2.8rem;
						font-weight: 700;
						color: $white;
					}
				}

				&:hover .title-alt {
					background-color: rgba($black, 0.4);
				}

				.title-alt {
					padding-right: 20px;
					padding-left: 20px;
					position: relative;

					height: 100%;

					background-color: rgba($black, 0.6);
					transition: background-color 0.2s ease-in-out;

					h5 {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						margin-bottom: 0;
						
						text-align: center;
					}
				}

				& > a {
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;

					&:hover .title {
						background-color: rgba($black, 0.4);
					}

					.title {
						height: 120px;

						background-color: rgba($black, 0.6);
						transition: background-color 0.2s ease-in-out;

						h5 {
							font-size: 1.8rem;
						}
					}

					.description {
						height: 60px;
						background-color: $dark-gray;

						position: relative;

						.lang {
							position: absolute;
							top: -20px;
							left: 20px;

							width: 40px;
							height: 40px;
							line-height: 40px;

							font-size: 1.5rem;
							text-align: center;
						}

						.type {
							position: absolute;
							right: 20px;
							bottom: 10px;

							font-size: 1.2rem;
						}
					}
				}
			}
		}

		.list-tabs {
			& > .tab {
				height: 0;
				overflow: hidden;

				&.active {
					height: auto;

					& > .before {
						bottom: 0;
					}
				}

				& > .before {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					z-index: -1;
				}

				ul.slider-default {
					padding-right: 20px;
					padding-left: 20px;

					margin-bottom: 0;
					margin-left: 0;

					position: relative;

					.slick-slide {
						position: relative;

						a {
							display: block;

							&:hover .image:before {
								background-color: rgba($black, 0.2);
							}

							.image {
								position: relative;

								& > img {
									width: 100%;
								}

								&:before {
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									z-index: 10;

									background-color: rgba($black, 0.4);
									transition: background-color 0.2s ease-in-out;

									content: "";
								}
							}

							& > * {
								font-family: "Helvetica Condensed", Arial, sans-serif;
								color: $white;
							}

							h5 {
								margin-top: 20px;
								margin-bottom: 0;

								font-family: "Helvetica Condensed", Arial, sans-serif;
								font-size: 2rem;
								line-height: 2.2rem;
							}

							.description {
								.lang {
									position: absolute;
									top: 20px;
									left: 20px;
									z-index: 12;

									width: 40px;
									height: 40px;
									line-height: 40px;

									font-size: 1.5rem;
									text-align: center;
								}

								.type {
									font-size: 1.5rem;
									font-weight: 300;
								}
							}
						}
					}

					.slick-arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						z-index: 10;

						width: 30px;
						height: 30px;

						background-color: $dark-gray;
						color: $white;

						&.slick-prev {
							left: 7px;

							&:before{
								font-family: "enpc";
								content: "\68";
							}
						}

						&.slick-next {
							right: 7px;

							&:before{
								font-family: "enpc";
								content: "\69";
							}
						}
					}
				}
			}
		}

		.select-list-tabs {
			margin-top: 20px;
			padding-right: 20px;
			padding-left: 20px;
		}
	}
}