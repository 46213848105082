main.contentpage section.section .view-teachers-list, main.contentpage section.section .node-teacher {
	.view-header {
		background-color: $light-gray;

		.description {
			h3 {
				color: $primary-color;
			}

			p {
				margin-bottom: 0;

				font-family: "Helvetica Condensed", Arial, sans-serif;
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}

		.avatar {
			img {
				width: 100%;
			}
		}
	}
}