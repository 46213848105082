.home-content {
	@include breakpoint(large up) {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	@include breakpoint(medium down) {
		padding: 40px 20px;
	}

	.region.region-home-content {
		@include breakpoint(medium only) {
			margin-right: -1.5rem;
			margin-left: -1.5rem;
		}

		& .row {
			@include breakpoint(large up) {
				padding-top: 80px;
			}

			@include breakpoint(medium down) {
				padding-top: 40px;
			}

      > .row {
        @include breakpoint(large up) {
          margin-top: 80px;
        }

        @include breakpoint(medium down) {
          margin-top: 40px;
        }
      }

		}
	}
}

#block-views-block-list-news-block, #block-views-block-list-news-block-1,
#block-views-block-list-event-block, #block-views-block-list-event-block-1 {
	@include breakpoint(medium up) {
		.item-list ul.slick-slider {
			padding-bottom: calc(60px + 3rem);
		}
	}

	li {
		a {
			display: flex;

			position: relative;

			@include breakpoint(medium up) {
				flex-direction: column-reverse;

				height: 280px;
			}

			@include breakpoint(small down) {
				flex-direction: column;

				width: 100%;
				min-height: 30px;

				background: none !important;

				& > img {
					width: 100%;
				}
			}

			&:hover .image:after {
				background-color: rgba($black, 0.2);
			}

			.image {
				flex: 1;

				&:after {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					z-index: 8;

					background-color: rgba($black, 0);
					transition: background-color 0.2s ease-in-out;

					content: "";
				}

				@include breakpoint(medium up) {
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					background-color: lighten($light-gray, 5%);

					& > img {
						display: none;
					}
				}

				@include breakpoint(small down) {
					width: 100%;
					min-height: 30px;

					background: none !important;

					& > img {
					    width: 100%;
					}
				}
			}

			.badge {
				@include breakpoint(medium up) {
					padding: 20px;

					background-color: $light-gray;
				}

				@include breakpoint(small down) {
					padding-top: 10px;
					padding-bottom: 10px;

					.categorie, .infos > .categorie {
						display: none;
					}
				}

				h4 {
					font-weight: 700;
					margin-top: 0;
					margin-bottom: 0;

					@include breakpoint(medium up) {
						font-size: 1.4rem;
					}

					@include breakpoint(small down) {
						font-size: 2rem;
					}
				}

				.date {
					font-size: 1.4rem;
					color: $primary-color;


                    .from {
                        @include breakpoint(medium up) {
							display: block;
                        }

                        @include breakpoint(small down) {
							display: inline;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}

	.slick-arrow {
		position: absolute;

		@include breakpoint(medium up) {
			width: 60px;
			height: 60px;

			bottom: 0;
			background-color: $light-gray;

			&:hover {
				background-color: darken($light-gray, 10%);
			}
		}

		@include breakpoint(small down) {
			width: 30px;
			height: 30px;

			top: 50%;
			transform: translateY(-50%);
			z-index: 10;

			background-color: $dark-gray;
			color: $white;
		}

		&.slick-prev {
			@include breakpoint(medium up) {
				left: 1.5rem;
			}

			@include breakpoint(small down) {
				left: -15px;
			}

			&:before{
				font-family: "enpc";
				content: "\68";
			}
		}

		&.slick-next {
			@include breakpoint(medium up) {
				left: calc(1.5rem + 62px);
			}

			@include breakpoint(small down) {
				 right: -15px;
			}

			&:before{
				font-family: "enpc";
				content: "\69";
			}
		}
	}

	.navigation {
		position: absolute;
		bottom: 0;
		left: 124px;

		a {
			display: inline-block;
			height: 60px;
			line-height: 60px;

			padding: 0 40px;

			background-color: $light-gray;

			text-transform: uppercase;
			text-align: center;

			font-size: 1.5rem;
			color: $black;

			&:hover {
				background-color: darken($light-gray, 10%);
			}
		}
	}
}

/*
#block-views-block-list-news-block, #block-views-block-list-news-block-1 {
	li {
		a {
			.badge {
				.categorie {
					position: absolute;
					right: 20px;
					bottom: -20px;

					height: 40px;
					width: 40px;
					line-height: 40px;

					background-color: $primary-color;
					text-align: center;

					&:before{
						font-size: 2rem;
						font-family: "enpc";
						content: "\6d";
						color: $white;
					}
				}
			}
		}
	}
}
*/

#block-views-block-list-news-block, #block-views-block-list-news-block-1,
#block-views-block-list-event-block, #block-views-block-list-event-block-1 {
	@include breakpoint(medium down) {
		margin-top: 40px;
	}

	li {
		a {
			.badge {
				.infos {
					@include breakpoint(medium up) {
						margin-bottom: 10px;
					}

					& > * {
						display: inline-block;
						vertical-align: middle;
					}

					.categorie {
						height: 40px;
						width: 40px;
						line-height: 40px;
						margin-right: 5px;

						background-color: $primary-color;
						text-align: center;

						&:before{
							font-size: 2rem;
							font-family: "enpc";
							color: $white;
						}
					}
				}
			}
		}
	}
}

#block-views-block-list-news-block, #block-views-block-list-news-block-1 {
	li a .badge .infos .categorie:before{
		content: "\6d";
	}
}

#block-views-block-list-event-block, #block-views-block-list-event-block-1 {
	li a .badge .infos .categorie:before{
		content: "\66";
	}
}
