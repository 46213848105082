header#header {
	width: 100%;
	max-width: 120rem;

	margin-right: auto;
	margin-left: auto;

	@include breakpoint(large up) {
		padding-top: 20px;
		padding-right: 20px;
		padding-left: 20px;

		& > * {
			display: inline-block;
			vertical-align: middle;
		}
	}

	@include breakpoint(medium down) {
		position: relative;
	}

	button.open-menu {
		@include breakpoint(large up) {
			display: none;
		}

		position: absolute;
		top: 0;
		left: 0;

		width: 70px;
		height: 70px;

		.center {
			display: inline-block;
			width: 30px;
			overflow: hidden;

			margin-right: auto;
			margin-left: auto;

			span {
				display: block;
				width: 38px;
				height: 2px;

				background-color: $black;

				transition: all 0.2s ease-in-out;

				&:nth-child(1), &:nth-child(3) {
					transform-origin: 1px 1px;
				}

				&:nth-child(2) {
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}
		}

		&.open .center {
			span {
				&:nth-child(1) {
					transform: rotate(41deg);
				}

				&:nth-child(2) {
					opacity: 0;
				}

				&:nth-child(3) {
					transform: rotate(-41deg);
				}
			}
		}
	}

	& > a#logo {
		@include breakpoint(large up) {
			margin-bottom: 20px;
		}

		@include breakpoint(medium down) {
			display: block;
			height: 70px;
			width: 70px;
			padding: 1.5rem;
			margin-right: auto;
			margin-bottom: 0;
			margin-left: auto;
		}

		& > img {
			@include breakpoint(medium down) {
				display: none;
			}

			width: 100px;
		}

		& > .icon-enpc {
			display: block;

			@include breakpoint(large up) {
				display: none;
			}

			font-size: 4rem;
			color: $black;
		}
	}

	.action {
		@include breakpoint(large up) {
			width: calc(100% - 100px);
		}

		@include breakpoint(medium down) {
			position: absolute;
			top: 0;
			right: 0;

			.wrapper-menu-mobile {
				position: fixed;
				top: 70px;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 100;
				overflow-x: hidden;
				overflow-y: auto; 

				background-color: $white;
				border-top: 1px solid $light-gray;

				transform: translate3d(-100%, 0, 0);
				transition: transform 0.2s ease-in-out;

				&.open {
					transform: translate3d(0, 0, 0);
				}
			}
		}

		nav {
			& > ul {
				margin-bottom: 0;
				margin-left: 0;

				& > li {
					padding-top: 0;
					padding-right: 0;
					margin-left: 0;

					font-size: 1.4rem;

					list-style: none;
				}
			}
		}

		nav#language {
			@include breakpoint(medium down) {
				padding-right: 15px;
				padding-left: 15px;
			}

			ul {
				font-size: 0;

				& > li {
					display: inline-block;
					vertical-align: middle;

					& + li {
						margin-left: 10px;
					}

					& > a {
						display: block;
						width: 30px;

						@include breakpoint(large up) {
							height: 30px;
						}

						@include breakpoint(medium down) {
							height: 70px;
						}

						overflow: hidden;
						text-indent: 100%;

						background-repeat: no-repeat;
						background-position: center;
						background-size: 24px;

						&.chinese { background-image: url(../images/china-flag.svg); }
						&.english { background-image: url(../images/england-flag.svg); }
						&.french { background-image: url(../images/france-flag.svg); } 
					}
				}
			}
		}

		.search-social {
			margin-top: 15px;

			& > * {
				display: inline-block;
				vertical-align: middle;

				& + * {
					margin-left: 30px;
				}
			}

			#search {
				form {
					.container-inline {
						font-size: 0;

						div, label {
							display: inline-block;
							vertical-align: middle;

							font-size: 1.4rem;
						}

						.form-item {
							margin-top: 0;
							margin-bottom: 0;
						}
					}

					input {
						height: 30px;

						border: none;
					}

					.form-item-search-block-form {
						& > input {
							width: 150px;
							padding: 0 8px;

							background-color: $light-gray;

							font-size: 1.5rem;
							text-transform: none;
						}
					}

					.form-actions {
						& > input {
							width: 30px;
							padding: 0;

							overflow: hidden;
							text-indent: 100%;

							background: $light-gray url(../images/search.svg) no-repeat center;
							background-size: 18px;
						}
					}
				}
			}

			nav#social-network {
				ul {
					font-size: 0;

					& > li {
						display: inline-block;
						vertical-align: middle;
						
						& + li {
							margin-left: 10px;
						}

						& > a {
							display: block;
							width: 30px;
							height: 30px;

							overflow: hidden;
							text-indent: 100%;

							border-radius: 50%;

							transition: background-color 0.2s ease-in-out;

							&:hover {
								background-color: $light-gray;
							}

							position: relative;

							&:after {
								position: absolute;
								top: 50%;
								left: 0;
								transform: translateY(-50%);

								width: 30px;

								font-family: "enpc";
								font-size: 1.8rem;
								text-align: center;
								text-indent: 0;
								color: $dark-gray;
							}

							&.facebook:after { content: "\62"; }
							&.twitter:after { content: "\63"; } 
							&.youtube:after { content: "\64"; }
							&.linkedin:after { content: "\70"; } 
							&.dailymotion:after { content: "\65"; } 
							&.instagram:after { content: "\7A"; } 
						}
					}
				}
			}
		}

		nav#main-menu {
			@include breakpoint(large up) {
				margin-top: 10px;
				margin-right: -20px;
			}

			@include breakpoint(medium down) {
				ul {
					li {
						border-bottom: 1px solid $light-gray;
					}
				}
			}

			& > ul {
				@include breakpoint(large up) {
					font-size: 0;
				}

				& > li {
					@include breakpoint(large up) {
						display: inline-block;
						vertical-align: middle;

						position: relative;

						&:hover {
							background-color: $light-gray;
						}

						& > a, & > span {
							line-height: 30px;
							padding: 20px;
							text-transform: uppercase;

							&.active {
								font-weight: 400;
							}
						}

						.action-mobile {
							display: none;
						}
					}

					.sub-menu {
						@include breakpoint(medium down) {
							position: absolute;
							top: 0;
							right: 100%;
							bottom: 0;
							left: -100%;
							z-index: 200;
							overflow-x: hidden;
							overflow-y: auto;

							background-color: $white;

							transition: transform 0.2s ease-in-out;

							&.open {
								transform: translate3d(100%, 0, 0);
							}
						}

						.action-mobile {
							border-bottom: 1px solid $light-gray;

							& > * {
								display: inline-block;
								vertical-align: middle;
							}

							& > button {
								width: 64px;
								height: 64px;

								& + button {
									border-left: 1px solid $light-gray;
								}
							}

							& > a, & > span {
								width: calc(100% - 128px);
								height: 64px;
								line-height: 64px;

								padding-right: 20px;
								padding-left: 20px;

								background-color: $primary-color;

								text-transform: uppercase;
								font-size: 1.5rem;
								color: $white;

								position: relative;

								&:after {
									position: absolute;
									top: 50%;
									right: 20px;
									transform: translateY(-50%);

									font-family: "enpc";
									text-transform: none;
									content: "\69";
								}
							}
						}

						ul.menu {
							margin-left: 0;

							& > li {
								list-style: none;

								padding-top: 0;
								padding-right: 0;
								margin-left: 0;
							}
						}
					}

					@include breakpoint(large up) {
						&:hover {
							& > .sub-menu {
								display: block;
							}
						}

						& > .sub-menu {
							display: none;

							position: absolute;
							top: 100%;
							left: 0;
							z-index: 100;

							min-width: 175px;

							background-color: $white;

							box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.24);
							border-radius: 0 0 2px 2px;
							border-right: 1px solid lighten($light-gray, 5%);
							border-left: 1px solid lighten($light-gray, 5%);

							& > ul.menu {
								& > li {
									border-top: 1px solid $light-gray;

									& > a, & > span {
										display: block;
										padding: 10px;
									}

									& > .sub-menu > ul.menu {
										display: none;
										
										background-color: lighten($light-gray, 4%);

										& > li {
											border-top: 1px solid $light-gray;

											& > a, & > span {
												padding: 10px 10px 10px 20px;
											}
										}
									}
								}

								li > a, & > span {
									&.active, &:hover {
										position: relative;

										&:before {
											position: absolute;
											left: 0;
											top: 0;
											bottom: 0;

											width: 2px;
											background-color: $primary-color;

											content: "";
										}
									}
								}
							}
						}
					}
				}

				li > a, li > span {
					display: block;

					font-size: 1.5rem;
					font-weight: 300;
					color: $black;

					@include breakpoint(medium down) {
						padding: 20px 40px 20px 20px;

						position: relative;

						&:after {
							position: absolute;
							top: 50%;
							right: 20px;
							transform: translateY(-50%);

							font-family: "enpc";
							text-transform: none;
							content: "\69";
						}
					}
				}
			}
		}
	}
}