table.intuitionpro {
	//white-space: nowrap;
	//table-layout: fixed;
	border-collapse: collapse;

	position: relative;
	width: 100%;
	max-width: 100%;

	border: 1px solid rgba($black, .12);

	tr {
		padding: 0;

		th, td {
			height: 48px;
			padding: 12px;

			overflow: hidden;
			text-overflow: ellipsis;
			letter-spacing: 0;

			text-align: left;
		}

		td {
			border-top: 1px solid rgba($black, .12);
			border-bottom: 1px solid rgba($black, .12);

			font-size: 1.4rem;
		}
	}

	thead{
		tr{
			th {
				font-family: "Helvetica Condensed", Arial, sans-serif;
				text-transform: uppercase;
				font-size: 1.4rem;
				font-weight: 600;
				color: $primary-color;

				vertical-align: bottom;
				border: 0;
			}
		}
	}

	tbody {
		tr {
			&:nth-child(odd) {
				background-color: $light-gray;
			}

			&:nth-child(even) {
				background-color: $white;
			}
		}
	}

	tfoot {
		tr{
			th {
				border-top: 1px solid rgba($black, .12);
				border-bottom: 1px solid rgba($black, .12);
			}
		}
	}
}

* + table.intuitionproapply {
	margin-top: 20px;
}

table.intuitionproapply {
	border-collapse: collapse;

	position: relative;
	max-width: 100%;

	tbody {
		border: none;

		tr {
			padding: 0;

			td {
				padding-right: 15px;

				a {
					display: inline-block;
					height: 40px;
					line-height: 40px;
					padding-right: 40px;
					padding-left: 40px;

					background-color: $primary-color;

					font-weight: 300;
					text-align: center;
					font-size: 2rem;
					color: $white;
				}
			}
		}
	}
}