.home-sub-header {
    background-color: $light-gray;

    #block-views-home-highlight-block {
        @include breakpoint(medium up) {
            padding: 40px 0;
        }

        @include breakpoint(small down) {
            padding: 20px;
        }

        ul.slick-slider {
            @include breakpoint(medium up) {
                padding-right: calc(60px + 1.5rem);
                padding-left: calc(60px + 1.5rem);
            }

            li {
                h4 {
                    font-weight: 700;
                }

                .image img {
                    width: 100% !important;
                }

                .summary{
                    margin-top: 10px;
                    line-height: 1.6rem;
                    font-size: 1.6rem;
                    color: $black;
                }

                & > a {
                    display: inline-block;
                    margin-top: 10px;
                    font-size: 1.4rem;

                    &:after {
                        margin-left: 5px;
                        vertical-align: middle;

                        font-family: "enpc";
                        content: "\69";
                    }
                }
            }

            .slick-arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                width: 60px;
                height: 60px;

                font-size: 5rem;
                color: $darker-light-gray;

                &.slick-prev:before, &.slick-next:before {
                    display: block;
                }

                &.slick-prev {
                    left: 1.5rem;

                    &:before {
                        font-family: "enpc";
                        content: "\68";
                    }
                }

                &.slick-next {
                    right: 1.5rem;

                    &:before {
                        font-family: "enpc";
                        content: "\69";
                    }
                }
            }

            ul.slick-dots {
                position: static;
                margin-top: 20px;
                margin-bottom: 0;

                li {
                    width: 15px;
                    height: 15px;
                    margin: 0;

                    button {
                        width: 15px;
                        height: 15px;
                        padding: 0;
                        margin: 0;

                        &:before {
                            width: 15px;
                            height: 15px;
                            line-height: 15px;
                            
                            opacity: 1;
                            color: white;
                        }
                    }

                    &.slick-active button:before {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}