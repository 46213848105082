main.contentpage {
	@include breakpoint(medium up) {
		padding: 40px 20px;
	}

	@include breakpoint(small down) {
		padding: 20px 20px 40px 20px;
	}

	section.section {
		position: relative;

		& > h2 {
			padding-right: 80px;
			margin-bottom: 0;
		}

		& > a.print {
			position: absolute;
			top: 0;
			right: 0;

			display: block;

			&:hover > i:before {
				background-color: $light-gray;
			}

			& > i {
				display: block;

				&:before {
					display: block;

					border-radius: 50%;

					text-align: center;
					color: $black;

					transition: background-color 0.2s ease-in-out;

					@include breakpoint(medium up) {
						height: 60px;
						width: 60px;

						font-size: 3rem;
						line-height: 6rem;
					}
				
					@include breakpoint(small down) {
						height: 40px;
						width: 40px;

						font-size: 2rem;
						line-height: 4rem;
					}
				}
			}
		}

		h3 {
			text-transform: uppercase;
			font-weight: 600;
			color: $primary-color;

			margin-bottom: 0;

			@include breakpoint(medium up) {
				font-size: 2.8rem;
				line-height: 3.4rem;
			}

			@include breakpoint(small down) {
				font-size: 1.8rem;
				line-height: 2rem;
			}

			& + * {
				margin-top: 10px;
			}
		}

		.node-page {
			@include breakpoint(medium up) {
				margin-top: 40px;
			}

			@include breakpoint(small down) {
				margin-top: 20px;

				& > .row {
					margin-right: 0;
					margin-left: 0;
				}
			}
		}

		ul.page-navtabs-list {
			list-style: none;
			margin-left: -1px;
			margin-top: -1px;
			margin-bottom: 30px;

			font-size: 0;

			& > li {
				display: inline-block;
				vertical-align: middle;

				background-color: $light-gray;
				border-top: 1px solid $white;
				border-left: 1px solid $white;

				&.active {
					background-color: $primary-color;

					& > a {
						font-weight: 600;
						color: $white;
					}
				}

				& > a {
					display: block;
					height: 60px;
					line-height: 60px;
					padding-right: 10px;
					padding-left: 10px;

					font-size: 1.5rem;
					font-weight: 300;
					text-align: center;
					text-transform: uppercase;
					color: $medium-gray;
				}
			}
		}

		.node-news, .node-event {
			.infos {
				margin-top: 20px;
				margin-bottom: 20px;
				
				font-family: "Helvetica Condensed", Arial, sans-serif;

				.date {
					font-size: 1.5rem;
					color: $primary-color;
				}

				.category {
					font-size: 2.8rem;
					font-weight: 700;
				}
			}
		}
        
        article.nav-by-tabs{
            .highlight{
                margin-bottom:30px;
            }
        }
        
		article {
			@include breakpoint(small down) {
				h3, p {
					&.rtejustify img {
						float: none !important;
						width: 100% !important;
						height: auto !important;
						margin: 0 0 20px 0 !important;
					}
				}
			}

			p {
				margin-bottom: 0;

				font-family: "Helvetica Condensed", Arial, sans-serif;
				font-size: 1.6rem;
				line-height: 2rem;

				& + * {
					margin-top: 10px;
				}
			}

			.gmap + * {
				@include breakpoint(medium up) {
					margin-top: 30px;
				}

				@include breakpoint(small down) {
					margin-top: 20px;
				}
			}

			.editor {
				ol {
					margin-left: 1.5rem;
				}

				ul {
					margin-left: 1.8rem;
				}

				ol, ul {
					font-family: "Helvetica Condensed", Arial, sans-serif;
					margin-bottom: 0;

					& + * {
						margin-top: 10px;
					}

					& > li {
						font-size: 1.6rem;
						line-height: 2rem;

						& + li {
							margin-top: 5px;
						}
					}
				}
			}

			* + .photo-gallery {
				margin-top: 30px;
			}

			* + h3 {
				margin-top: 30px;
			}

			* + h4 {
				margin-top: 20px;
			}

			.highlight {
				background-color: $light-gray;

				.description {
					@include breakpoint(medium up) {
						padding: 30px;
					}

					@include breakpoint(small down) {
						padding: 20px;
					}
				}

				& + * {
					@include breakpoint(medium up) {
						margin-top: 30px;
					}

					@include breakpoint(small down) {
						margin-top: 20px;
					}
				}
			}

			.child-node-list {
				& + * {
					margin-top: 40px;
				}

				ul {
					list-style: none;
					margin: 20px 0 0 0;

					& > li {
						font-size: 1.6rem;
						line-height: 2rem;

						&:after {
							content: "";
							display: block;
							clear: both;
							visibility: hidden;
							line-height: 0;
							height: 0;
						}

						& + li {
							margin-top: 20px;
						}

						& > * {
							& + * {
								margin-top: 10px;
							}
						}

						h3 {
							margin-bottom: 15px;
						}

						& > .image {
							float: left;
							width: 100%;
							max-width: 150px;
							margin: 0 20px 20px 0;
						}

						& > a {
							display: inline-block;
						}
					}
				}
			}

			.social-media-link {
				text-align: right;

				.sharethis-wrapper {
					display: inline-block;

					& > span {
						display: inline-block;

						&:after {
							display: block;
							height: 40px;
							line-height: 40px;
							width: 40px;

							border-radius: 50%;
							cursor: pointer;

							font-family: "enpc";
							font-size: 1.6rem;
							text-align: center;
							color: $white;

							transition: background-color 0.2s ease-in-out;
						}

						& + span {
							margin-left: 10px;
						}

						&.st_facebook_custom:after {
							background-color: $facebook;
							content: "\62";
						}

						&.st_facebook_custom:hover:after {
							background-color: darken($facebook, 10%);
						}

						&.st_twitter_custom:after {
							background-color: $twitter;
							content: "\63";
						}

						&.st_twitter_custom:hover:after {
							background-color: darken($twitter, 10%);
						}

						&.st_linkedin_custom:after {
							background-color: $linkedin;
							content: "\70";
						}

						&.st_linkedin_custom:hover:after {
							background-color: darken($linkedin, 10%);
						}
					}
				}
			}
		}

		.more-infos {
			@include breakpoint(large up) {
				h2 {
					font-size: 3.4rem;

					& + * {
						margin-top: 20px;
					}
				}
			}

			@include breakpoint(medium down) {
				margin-top: 30px;
			}

			.row-columns {
				@include breakpoint(large up) {
					column-count: 2;
					column-gap: 3rem;
					-webkit-perspective: 1;

					& > * {
						display: table; // Firefox 20+
						page-break-inside: avoid; // Firefox 20+
						break-inside: avoid-column; // Chrome, Safari
					}
				}
			}

			.row, .row-columns {
				@include breakpoint(large up) {
					& > * {
						padding-top: 40px;
					}
				}

				@include breakpoint(medium down) {
					& > * + * {
						margin-top: 20px;
					}
				}
			}

			.row > .columns > * + * {
				@include breakpoint(large up) {
					padding-top: 40px;
				}

				@include breakpoint(medium down) {
					margin-top: 20px;
				}
			}

			& > .photo-gallery {
				@include breakpoint(large up) {
					margin-top: 40px;
				}

				@include breakpoint(medium down) {
					& + * {
						margin-top: 20px;
					}
				}
			}

			.links, .documents {
				ul {
					margin-left: 0;
					margin-bottom: 0;
					list-style: none;

					& > li {
						& + li {
							border-top: 1px solid $light-gray;
						}

						& > a {
							display: block;
							padding-top: 10px;
							padding-bottom: 10px;

							& > * {
								font-family: "Helvetica Condensed", Arial, sans-serif;
							}

							h4 {
								margin-bottom: 0;
								font-size: 2rem;
								font-weight: 700;
							}

							span.description {
								display: block;

								font-size: 1.5rem;
								font-weight: 300;
								color: $black;
							}

							span.fake-link {
								display: inline-block;
								font-size: 1.4rem;

								&:after {
									margin-left: 5px;
									vertical-align: middle;

									font-family: "enpc";
									content: "\69";
								}
							}
						}
					}
				}
			}

			.documents {
				ul > li {
					& > a {
						padding-left: 60px;

						h4 span {
							font-size: 1.5rem;
							font-weight: 300;
							font-style: italic;
						}

						position: relative;

						&:before {
							position: absolute;
							top: 15px;
							left: 0;

							width: 40px;

							font-family: "enpc";
							text-align: center;
							font-size: 4rem;
							line-height: 4.2rem;
						}
					}

					&.pdf > a:before {
						content: "\71";
						font-size: 4rem;
						line-height: 4.2rem;
						color: #e74c3c;
					}

					&.doc, &.docx, &.odt, &.rtf {
						& > a:before {
							content: "\75";
							color: #3498db;
						}
					}

					&.xls, &.xlsx, &.ods {
						& > a:before {
							content: "\73";
							color: #2ecc71;
						}
					}

					&.ppt, &.pptx, &.odp {
						& > a:before {
							content: "\74";
							color: #d35400;
						}
					}

					&.jpg, &.jpeg, &.png {
						& > a:before {
							content: "\72";
							color: #f1c40f;
						}
					}

					&.zip, &.rar {
						& > a:before {
							content: "\76";
							color: #34495e;
						}
					}
				}
			}

			.contact {
				@include breakpoint(medium down) {
					h2 + * {
						margin-top: 20px;
					}

					.description {
						padding-right: 20px;
						padding-left: 20px;
					}
				}

				p {
					font-family: "Helvetica Condensed", Arial, sans-serif;
					font-size: 1.4rem;
				}
			}
		}


		.editor, .more-infos {
			.photo-gallery {
				.lightgallery {
					margin-left: 0;
					margin-bottom: 0;
					list-style: none;
					font-size: 0;

					@include breakpoint(large down) {
						margin-top: 20px;
						padding-right: 20px;
						padding-left: 20px;
					}

					display: flex;
					flex-wrap: wrap;
					align-content: flex-start;

					& > a {
						display: block;
						flex: auto;
						padding: 1px;

						@include breakpoint(large up) {
							max-width: 100px;
						}

						@include breakpoint(medium down) {
							max-width: 33.3333%;
						}

						img {
							width: 100%;
						}
					}
				}

				position: relative;

				ul.slick-slider.slider-photo {
					margin-left: 0;

					& + .lightgallery {
						position: absolute;
						z-index: -10;
						height: 0;
						visibility: hidden;
						margin: 0;
					}

					li {
						a {
							display: block;

							.image {
								@include breakpoint(medium up) {
									height: 400px;

									background-size: cover;
									background-position: center;
									background-repeat: no-repeat;
									background-color: lighten($light-gray, 5%);
				
									& > img {
										display: none;
									}
								}
				
								@include breakpoint(small down) {
									width: 100%;
									min-height: 30px;
				
									background: none !important;
				
									& > img {
										width: 100%;
									}
								}
							}
		
							.description {
								@include breakpoint(medium up) {
									margin-top: 20px;
								}
			
								@include breakpoint(small down) {
									padding-top: 10px;
									padding-bottom: 10px;
								}
			
								p {
									margin-bottom: 0;
									
									font-family: "Helvetica Condensed", Arial, sans-serif;
									font-size: 1.6rem;
									line-height: 2rem;
									color: $black;
								}
							}
						}
					}
			
					.slick-arrow {
						position: absolute;
						
						z-index: 9;
			
						@include breakpoint(medium up) {
							width: 60px;
							height: 60px;
			
							top: 340px;
							background-color: $light-gray;
			
			
							&:hover {
								background-color: darken($light-gray, 10%);
							}
						}
			
						@include breakpoint(small down) {
							width: 30px;
							height: 30px;
			
							top: 50%;
							transform: translateY(-50%);
							z-index: 10;
			
							background-color: $dark-gray;
							color: $white;
						}
			
						&.slick-prev {
							@include breakpoint(medium up) {
								right: 62px;
							}
			
							@include breakpoint(small down) {
								left: -15px;
							}
			
							&:before{
								font-family: "enpc";
								content: "\68";
							}
						}
			
						&.slick-next {
							@include breakpoint(medium up) {
								right: 0;
							}
			
							@include breakpoint(small down) {
								right: -15px;
							}
			
							&:before{
								font-family: "enpc";
								content: "\69";
							}
						}
					}
				}
			}
		}
	}
}