nav.breadcrumb {
	padding-bottom: 0;

	@include breakpoint(medium up) {
		& + * {
			margin-top: 20px;
		}
	}
	
	@include breakpoint(small down) {
		display: none;
	}

	& > ul.breadcrumb {
		padding: 0;
		margin: 0;

		& > li {
			display: inline-block;
			vertical-align: middle;

			&:nth-last-child(n+2) {
				margin-right: 20px;
				padding-right: 34px;

				position: relative;

				&:after {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);

					font-family: "enpc";
					font-size: 1.4rem;
					color: $medium-gray;
					content: "\69";
				}
			}

			& > * {
				font-family: "Helvetica Condensed", Arial, sans-serif;
				font-size: 1.6rem;
				color: $medium-gray;
			}
		}
	}
}