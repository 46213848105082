main.contentpage {
	.node-webform {
		@include breakpoint(medium up) {
			margin-top: 40px;
		}

		@include breakpoint(small down) {
			margin-top: 20px;
		}
	}

	form.webform-client-form {
		& > div > * + * {
			@include breakpoint(large up) {
				margin-top: 40px !important;
			}

			@include breakpoint(medium down) {
				margin-top: 30px !important;
			}
		}

		.webform-progressbar {
			width: 100%;

			padding-bottom: 36px;

			position: relative;

			.webform-progressbar-outer {
				position: static;

				width: 100%;
				height: 10px;
				margin: 0;

				border-radius: 5px;
				border: none;

				background-color: $light-gray;

				.webform-progressbar-inner {
					height: 10px;
					border-radius: 5px;
					background-color: $primary-color;
				}

				.webform-progressbar-page {
					top: 14px;

					height: auto;
					width: auto;
					margin: 0;

					border: none;
					border-radius: none;

					&.current, &.completed {
						background: none;
					}

					& + .webform-progressbar-page {
						transform: translateX(-50%);

						&:last-child {
							transform: translateX(-100%);
						}
					}

					.webform-progressbar-page-label {
						display: block;

						position: static;
						top: 0;
						margin: 0;

						font-size: 1.4rem;
						line-height: 1.6rem;
						color: $medium-gray;
					}
				}
			}

			.webform-progressbar-number {
				position: absolute;
				bottom: 0;
				width: 100%;

				font-size: 1.4rem;
				line-height: 1.6rem;
				color: $medium-gray;
			}
		}

		.form-item {
			margin-top: 0;
			margin-bottom: 0;

			&.files {
				label {
					@include breakpoint(medium up) {
						margin-bottom: 20px;

						font-size: 3rem;
						line-height: 3.2rem;
					}

					@include breakpoint(small down) {
						margin-bottom: 10px;

						font-size: 2rem;
						line-height: 2.2rem;
					}

					text-transform: uppercase;
					font-weight: 600;
				}

				.form-managed-file {
					@include breakpoint(medium up) {
						margin-right: -10px;
						margin-left: -10px;

						input[type="submit"] {
							width: calc(25% - 20px);
						}
					}

					& > * {
						@include breakpoint(medium up) {
							display: inline-block;
							vertical-align: middle;
							margin-right: 10px;
							margin-left: 10px;
						}

						@include breakpoint(small down) {
							& + * {
								margin-top: 10px;
							}
						}
					}

					input[type="file"], span.file {
						border: 2px solid $light-gray;
						color: $primary-color;

						@include breakpoint(large up) {
							width: calc(50% - 20px);
						}

						@include breakpoint(medium up) {
							padding: 23px 30px;
							font-size: 2rem;
							line-height: 3rem;
						}

						@include breakpoint(medium only) {
							width: calc(75% - 20px);
						}

						@include breakpoint(small down) {
							width: 100%;
							padding: 12px 20px;
							font-size: 1.4rem;
							line-height: 1.4rem;
						}
					}
				}

				.description {
					margin-top: 10px;
					color: $medium-gray;

					@include breakpoint(medium only) {
						font-size: 1.8rem;
					}

					@include breakpoint(small down) {
						font-size: 1.4rem;
					}

					strong {
						font-weight: normal;
					}
				}
			}

			input.input, textarea.textarea, select.select {
				border: 2px solid $light-gray;
			}
		}

		.radios {
			.form-item {
				& + .form-item {
					@include breakpoint(medium only) {
						margin-top: 12px;
					}

					@include breakpoint(small down) {
						margin-top: 8px;
					}
				}

				input[type=radio] {
					position: absolute;
					z-index: -10;
					visibility: hidden;

					& + label {
						line-height: 2.6rem;
						cursor: pointer;

						@include breakpoint(medium only) {
							padding-left: 36px;
							font-size: 1.8rem;
						}

						@include breakpoint(small down) {
							padding-left: 32px;
							font-size: 1.4rem;
						}

						position: relative;

						&:before {
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);

							border: 2px solid $light-gray;
							background: white;

							content: '';

							@include breakpoint(medium only) {
								height: 26px;
								width: 26px;
							}

							@include breakpoint(small down) {
								height: 22px;
								width: 22px;
							}
						}
					}

					&:checked + label:after {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);

						height: 8px;
						width: 8px;

						background-color: $black;

						content: '';

						@include breakpoint(medium only) {
							left: 9px;
						}

						@include breakpoint(small down) {
							left: 7px;
						}
					}

					& + label:before, &:checked + label:after {
						border-radius: 50%;
					}
				}
			}
		}

		fieldset.fieldset {
			margin-right: 0;
			margin-bottom: 0;
			margin-left: 0;
			padding: 0;
			border: none;

			@include breakpoint(large up) {
				&.inline .fieldset-wrapper > * {
					display: inline-block;
					vertical-align: middle;
				}
			}

			.fieldset-wrapper {
				font-size: 0;

				@include breakpoint(large up) {
					margin-right: -10px;
					margin-left: -10px;
				}

				& > * {
					@include breakpoint(large up) {
						width: 50%;

						padding-right: 10px;
						padding-left: 10px;
					}

					@include breakpoint(medium down) {
						& + * {
							margin-top: 10px;
						}
					}
				}

				.date, .hour {
					.webform-container-inline {
						padding-right: 40px;

						background-repeat: no-repeat;
						background-size: 20px auto;
						background-position: right 10px center;

						@include breakpoint(medium up) {
							margin-left: -10px;
						}

						& > .form-item {
							@include breakpoint(medium up) {
								display: inline-block;
								vertical-align: middle;
								padding-right: 10px;
								padding-left: 10px;
							}

							@include breakpoint(small down) {
								display: block;

								& + .form-item {
									margin-top: 10px;
								}
							}

							select {
								border: 2px solid $light-gray;
							}
						}
					}
				}

				.date .webform-container-inline {
					background-image: url(../images/date.svg);

					@include breakpoint(medium up) {
						& > .form-item {
							width: 33.3333%;
						}
					}

					position: relative;

					& > input.webform-calendar {
						position: absolute;
						top: 0;
						right: 0;

						width: 40px;
						height: 100%;
						padding: 0;

						opacity: 0;
					}
				}

				.hour .webform-container-inline {
					background-image: url(../images/hour.svg);

					@include breakpoint(medium up) {
						& > .form-item {
							width: 50%;
						}
					}
				}
			}
		}

		fieldset.group {
			background-color: $light-gray;

			margin-right: 0;
			margin-bottom: 0;
			margin-left: 0;
			border: none;

			@include breakpoint(large up) {
				width: 50%;
			}

			@include breakpoint(medium up) {
				padding: 40px 60px;
			}

			@include breakpoint(small down) {
				padding: 20px;
			}

			legend {
				display: inline-block;

				text-transform: uppercase;
				font-weight: 600;

				@include breakpoint(medium up) {
					margin-bottom: 20px;

					font-size: 3rem;
					line-height: 3.2rem;
				}

				@include breakpoint(small down) {
					margin-bottom: 10px;

					font-size: 2rem;
					line-height: 2.2rem;
				}
			}

			.fieldset-wrapper {
				& > * + * {
					margin-top: 10px;
				}
				
				input, select, textarea {
					border: none;
				}
			}
		}

		.form-actions {
			margin-bottom: 0;

			@include breakpoint(large up) {
				width: 50%;
			}

			& > * {
				display: inline-block;
				vertical-align: middle;
				width: calc(50% - 10px);

				& + * {
					margin-left: 20px;
				}
			}
		}
	}
}