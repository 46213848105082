.home-content {
	#block-enpc-blocks-home-map {
		.content {
			position: relative;

			ul {
				position: absolute;
				top: 20px;
				left: 20px;
				//transform: translateY(-50%);

				background-color: $white;
				border-radius: 2px;
				box-shadow: 0px 0px 10px 0px $medium-gray;

				margin-bottom: 0;
				margin-left: 0;

				list-style: none;

				li {
					a {
						display: block;
						padding: 30px 20px;

						font-family: "Helvetica Condensed", Arial, sans-serif;
						font-weight: 300;
						font-size: 1.4rem;

						color: $medium-gray;

						&:hover {
							color: $black;
						}
					}

					& + li {
						padding-top: 1px;
						position: relative;

						&:before {
							position: absolute;
							top: 0;
							left: 20px;

							height: 1px;
							width: calc(100% - 40px);

							background-color: $light-gray;

							content: "";
						}
					}
				}
			}

			select {
				border: 1px solid $light-gray;
			}
		}
	}
}