.home-content {
	#block-enpc-blocks-key-figures {
		.datas {
			margin: 0;
			
			& > * {
				height: 145px;

				padding-right: 20px;
				padding-left: 20px;

				font-family: "Helvetica Condensed", Arial, sans-serif;
				text-transform: uppercase;
				font-weight: 700;
			}

			& > div {
				padding-top: 40px;

				.number {
					font-size: 3.6rem;
					line-height: 3.6rem;
				}
				
				.text {
					margin-top: 5px;
					font-size: 1.2rem;
					line-height: 1.2rem;
				}
			}

			.dark-gray {
				background-color: $dark-gray;

				font-size: 2rem;
				line-height: 3.2rem;
				color: $white;
			}

			.primary {
				background-color: $primary-color;
				color: $white;
			}

			a.light-gray {
				background-color: $light-gray;

				font-size: 1.8rem;
				color: $dark-gray;

				&:hover {
					background-color: darken($light-gray, 10%);
				}

				.table {
					margin: 0 auto;
				}
			}
		}
	}
}