main.contentpage section.section .node-teacher {
	@include breakpoint(medium up) {
		margin-top: 40px;
	}

	@include breakpoint(small down) {
		margin-top: 20px;
	}

	article {
		& > * + * {
			margin-top: 30px;
		}

		h4 + * {
			margin-top: 10px;
		}

		.view-header {
			padding: 20px;

			p {
				.label {
					color: $primary-color;
				}
			}

			.avatar {
				//margin-top: 20px;

				@include breakpoint(medium down) {
					& > img {
						max-width: 200px;
					}
				}
			}
		}

		.editor > * + * {
			margin-top: 30px;
		}
	}
}