
/* ------------------------------------------------------------------------------- */
/* -- FONTS ---------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */
/**/


@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=15a3af66-dfcc-4eee-8ea1-81a38e449417");


/* -- HELVETICA CONDENSED -------------------------------------------------------- */

@font-face { // LIGHT
	font-family: "Helvetica Condensed";
	src: url("../fonts/helvetica/80052558-1d8d-4833-ba0e-aa66dfb53bd3.eot?#iefix");
	src: url("../fonts/helvetica/80052558-1d8d-4833-ba0e-aa66dfb53bd3.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/da1e3389-7cb1-4bbe-a8cb-9d8c12bed489.woff2") format("woff2"),
		 url("../fonts/helvetica/a9b0bbff-d4ce-481a-ade4-32c7e112125f.woff") format("woff"),
		 url("../fonts/helvetica/44ee152f-0853-4294-bf2e-22398749a8b8.ttf") format("truetype"),
		 url("../fonts/helvetica/accf64c0-b1a7-46c6-ae85-cedefce0cf3d.svg#accf64c0-b1a7-46c6-ae85-cedefce0cf3d") format("svg");
	font-weight: 300;
	font-style: normal;
}

@font-face { // LIGHT OBLIQUE
	font-family: "Helvetica Condensed";
	src: url("../fonts/helvetica/3d94be47-b5cc-4ed2-902b-cabd3c338c6a.eot?#iefix");
	src: url("../fonts/helvetica/3d94be47-b5cc-4ed2-902b-cabd3c338c6a.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/ae28463c-ff37-41b4-b03c-4ccb49d556f2.woff2") format("woff2"),
		 url("../fonts/helvetica/a3434dce-e398-47cf-8746-83306c679298.woff") format("woff"),
		 url("../fonts/helvetica/99dc4891-1370-425f-829e-95ba2b6db9e8.ttf") format("truetype"),
		 url("../fonts/helvetica/db067d2e-b7a2-4f8c-a344-c453c7ed0b6a.svg#db067d2e-b7a2-4f8c-a344-c453c7ed0b6a") format("svg");
	font-weight: 300;
	font-style: italic;
}

@font-face { // REGULAR
	font-family: "Helvetica Condensed";
	src: url("../fonts/helvetica/dca77a69-d0f5-422b-bb06-8c937e8b648f.eot?#iefix");
	src: url("../fonts/helvetica/dca77a69-d0f5-422b-bb06-8c937e8b648f.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/b2dc2dea-700a-4f4a-8d54-410cab0563eb.woff2") format("woff2"),
		 url("../fonts/helvetica/db750bb2-16e0-4166-a692-163c51ea3190.woff") format("woff"),
		 url("../fonts/helvetica/35dbbf8d-05aa-482d-809e-ab29b98320e6.ttf") format("truetype"),
		 url("../fonts/helvetica/2f51b4ec-8bfe-4302-9e97-4b6c7bb6ae2e.svg#2f51b4ec-8bfe-4302-9e97-4b6c7bb6ae2e") format("svg");
	font-weight: 400;
	font-style: normal;
}

@font-face { // REGULAR OBLIQUE
	font-family: "Helvetica Condensed";
	src: url("../fonts/helvetica/39e9bb49-8b59-44fb-92be-ee2fb341a638.eot?#iefix");
	src: url("../fonts/helvetica/39e9bb49-8b59-44fb-92be-ee2fb341a638.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/836434bb-026b-4b96-9158-34f76cc2cdc6.woff2") format("woff2"),
		 url("../fonts/helvetica/57c443b8-3809-443e-90cb-82c5adee7c79.woff") format("woff"),
		 url("../fonts/helvetica/9b0cae73-97d6-437a-91dd-0e8b32b5ea17.ttf") format("truetype"),
		 url("../fonts/helvetica/7a2054f3-730f-4db8-ba76-811760ff5e68.svg#7a2054f3-730f-4db8-ba76-811760ff5e68") format("svg");
	font-weight: 400;
	font-style: italic;
}

@font-face { // BOLD
	font-family: "Helvetica Condensed";
	src: url("../fonts/helvetica/4834c70d-196c-47f6-bfd5-11e5d2e2f65c.eot?#iefix");
	src: url("../fonts/helvetica/4834c70d-196c-47f6-bfd5-11e5d2e2f65c.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/cc8baf39-7363-4096-9272-744ba5d42550.woff2") format("woff2"),
		 url("../fonts/helvetica/c3e64e07-d679-43dc-982a-6d21239f752e.woff") format("woff"),
		 url("../fonts/helvetica/b4e439ff-23ab-4b99-ae1e-1ba93635751e.ttf") format("truetype"),
		 url("../fonts/helvetica/045f30a1-52fd-4914-9cfb-32fe35e60b35.svg#045f30a1-52fd-4914-9cfb-32fe35e60b35") format("svg");
	font-weight: 700;
	font-style: normal;
}

@font-face { // BOLD OBLIQUE
	font-family: "Helvetica Condensed";
	src: url("../fonts/helvetica/fffa7158-d306-466a-a27a-85af176386c4.eot?#iefix");
	src: url("../fonts/helvetica/fffa7158-d306-466a-a27a-85af176386c4.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/2bf6c0ba-69c7-4dde-ae8e-80047b60ebc1.woff2") format("woff2"),
		 url("../fonts/helvetica/b659afed-b043-4147-84b5-8ef7fcdbbeef.woff") format("woff"),
		 url("../fonts/helvetica/99f5867a-2bea-4b24-a9b4-d24cf898f23b.ttf") format("truetype"),
		 url("../fonts/helvetica/e4a703c9-7da5-4482-a067-34444f136d56.svg#e4a703c9-7da5-4482-a067-34444f136d56") format("svg");
	font-weight: 700;
	font-style: italic;
}

@font-face { // BLACK
	font-family: "Helvetica Condensed";
	src: url("../fonts/helvetica/a6622a59-e099-4e4c-bafb-934504692544.eot?#iefix");
	src: url("../fonts/helvetica/a6622a59-e099-4e4c-bafb-934504692544.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/318f10c9-cc4d-47d1-a357-05d8a36bd49c.woff2") format("woff2"),
		 url("../fonts/helvetica/0ec178c4-7cd1-4236-8b55-ad77cb3ceae8.woff") format("woff"),
		 url("../fonts/helvetica/5c3d918e-6f54-46c7-8e09-65cc68919aa8.ttf") format("truetype"),
		 url("../fonts/helvetica/05407655-3145-4529-ad6c-fd7f87a5ed82.svg#05407655-3145-4529-ad6c-fd7f87a5ed82") format("svg");
	font-weight: 900;
	font-style: normal;
}

@font-face { // BLACK OBLIQUE
	font-family: "Helvetica Condensed";
	src: url("../fonts/helvetica/682dfbbc-ad57-4cde-af10-b5208e36e316.eot?#iefix");
	src: url("../fonts/helvetica/682dfbbc-ad57-4cde-af10-b5208e36e316.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/2d15f0a2-cc5c-478c-89d0-a5aebc6339ee.woff2") format("woff2"),
		 url("../fonts/helvetica/09e06020-4637-43c8-9783-bf71198454e5.woff") format("woff"),
		 url("../fonts/helvetica/8cd214f2-3184-4e74-a2fa-f28cf8d48dac.ttf") format("truetype"),
		 url("../fonts/helvetica/6ed78a49-75ec-4c58-a3d4-a854a98f4381.svg#6ed78a49-75ec-4c58-a3d4-a854a98f4381") format("svg");
	font-weight: 900;
	font-style: italic;
}


/* -- HELVETICA NEUE CONDENSED --------------------------------------------------- */

@font-face { // THIN
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/8912804c-dafb-4d04-82a4-e572d4782c6a.eot?#iefix");
	src: url("../fonts/helvetica/8912804c-dafb-4d04-82a4-e572d4782c6a.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/6d5ceb9f-137b-47e3-8438-465de99acf5a.woff2") format("woff2"),
		 url("../fonts/helvetica/627c276f-a6d6-4f1e-952a-460f1863ac23.woff") format("woff"),
		 url("../fonts/helvetica/0bfc2d60-7148-448b-9365-de1d5b911290.ttf") format("truetype"),
		 url("../fonts/helvetica/8167b508-e8d7-4e10-9970-02abee02e754.svg#8167b508-e8d7-4e10-9970-02abee02e754") format("svg");
	font-weight: 100;
	font-style: normal;
}

@font-face { // THIN OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/30f7bd4c-6226-43b2-abcc-f11fe8284066.eot?#iefix");
	src: url("../fonts/helvetica/30f7bd4c-6226-43b2-abcc-f11fe8284066.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/f5ea622c-b137-460e-8f4b-16b38240c473.woff2") format("woff2"),
		 url("../fonts/helvetica/d477d3c5-9380-4e12-b27e-e593615893d8.woff") format("woff"),
		 url("../fonts/helvetica/2c455c2e-6622-46c6-a871-6d3ebc517947.ttf") format("truetype"),
		 url("../fonts/helvetica/d69b1ecd-fe27-449a-a724-15a933f141c9.svg#d69b1ecd-fe27-449a-a724-15a933f141c9") format("svg");
	font-weight: 100;
	font-style: italic;
}

@font-face { // EXTRA LIGHT
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/a95f229f-9ef5-4f43-a4a2-2bbc8dc6612a.eot?#iefix");
	src: url("../fonts/helvetica/a95f229f-9ef5-4f43-a4a2-2bbc8dc6612a.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/d6f92a91-b2ac-4e80-a3e7-930719d81079.woff2") format("woff2"),
		 url("../fonts/helvetica/cabea84c-04f4-424a-a992-d75f0b372af0.woff") format("woff"),
		 url("../fonts/helvetica/ca61652b-8b38-4471-bc42-eca2d8d2993d.ttf") format("truetype"),
		 url("../fonts/helvetica/4b6aad5d-9c23-4bec-8db0-4733f91f2a60.svg#4b6aad5d-9c23-4bec-8db0-4733f91f2a60") format("svg");
	font-weight: 200;
	font-style: normal;
}

@font-face { // EXTRA LIGHT OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/23e70686-8eaf-48d4-bc55-de0ef59ec1b2.eot?#iefix");
	src: url("../fonts/helvetica/23e70686-8eaf-48d4-bc55-de0ef59ec1b2.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/83b9d78a-d299-4ea0-af62-62f1b25f3343.woff2") format("woff2"),
		 url("../fonts/helvetica/a3c725b9-0ea8-4d86-8fcd-c1f39445aaf4.woff") format("woff"),
		 url("../fonts/helvetica/f6778796-3131-48d4-9449-18239b93c977.ttf") format("truetype"),
		 url("../fonts/helvetica/2b64588e-27b9-4755-ba77-ee2dbab2cb68.svg#2b64588e-27b9-4755-ba77-ee2dbab2cb68") format("svg");
	font-weight: 200;
	font-style: italic;
}

@font-face { // LIGHT
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/ee259578-3cae-4dd8-a23a-297d352ec2dc.eot?#iefix");
	src: url("../fonts/helvetica/ee259578-3cae-4dd8-a23a-297d352ec2dc.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/244ff804-5735-41ed-92a1-ce5438185e9c.woff2") format("woff2"),
		 url("../fonts/helvetica/9cd26200-0d62-40ef-94b8-46fd1d8b255f.woff") format("woff"),
		 url("../fonts/helvetica/7b72d33f-8b87-4c48-b397-42d28164d598.ttf") format("truetype"),
		 url("../fonts/helvetica/07dba647-d830-4ba8-8b84-1e4c9858f3d3.svg#07dba647-d830-4ba8-8b84-1e4c9858f3d3") format("svg");
	font-weight: 300;
	font-style: normal;
}

@font-face { // LIGHT OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/c170bbb4-388a-43e8-971b-6b947385ee1d.eot?#iefix");
	src: url("../fonts/helvetica/c170bbb4-388a-43e8-971b-6b947385ee1d.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/c6c74d5e-aa58-4006-85a0-27096c6827b8.woff2") format("woff2"),
		 url("../fonts/helvetica/5fccf761-c079-464e-8a75-d60112d84d26.woff") format("woff"),
		 url("../fonts/helvetica/a8223598-2333-4c7c-98aa-c40ff94a1656.ttf") format("truetype"),
		 url("../fonts/helvetica/a834940c-66a8-439a-bce9-f123cde788c9.svg#a834940c-66a8-439a-bce9-f123cde788c9") format("svg");
	font-weight: 300;
	font-style: italic;
}

@font-face { // REGULAR
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/3808766e-26cf-47cb-97a7-1f1c64db9c48.eot?#iefix");
	src: url("../fonts/helvetica/3808766e-26cf-47cb-97a7-1f1c64db9c48.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/946b2f3f-a9d7-47e4-9738-ee963004d697.woff2") format("woff2"),
		 url("../fonts/helvetica/ce90a9c3-4580-4fa7-b453-9a4e86d0c271.woff") format("woff"),
		 url("../fonts/helvetica/a621a6bf-fa40-4115-9f42-6ffb55c017e2.ttf") format("truetype"),
		 url("../fonts/helvetica/436f9358-58a7-4f85-bdef-f4cd10831df6.svg#436f9358-58a7-4f85-bdef-f4cd10831df6") format("svg");
	font-weight: 400;
	font-style: normal;
}

@font-face { // REGULAR OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/afa6040d-15b1-44a1-8d96-ac4bf08b88e0.eot?#iefix");
	src: url("../fonts/helvetica/afa6040d-15b1-44a1-8d96-ac4bf08b88e0.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/c105a2ab-affc-4d8b-af03-a091adb0ce8d.woff2") format("woff2"),
		 url("../fonts/helvetica/4fe57c2d-a37a-4c08-8264-0dcda238b39e.woff") format("woff"),
		 url("../fonts/helvetica/d3c65138-d901-48c5-a694-74c11336db3f.ttf") format("truetype"),
		 url("../fonts/helvetica/19d8ce57-2ac2-4881-9263-b8d619be86e4.svg#19d8ce57-2ac2-4881-9263-b8d619be86e4") format("svg");
	font-weight: 400;
	font-style: italic;
}

@font-face { // MEDIUM
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/13e5efa8-b7a2-4f0f-a459-c966f7d3f458.eot?#iefix");
	src: url("../fonts/helvetica/13e5efa8-b7a2-4f0f-a459-c966f7d3f458.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/108e1ff6-3205-4c92-9aa5-9abb2823fe92.woff2") format("woff2"),
		 url("../fonts/helvetica/f61c722b-9236-445e-bb7e-eeb1009cd627.woff") format("woff"),
		 url("../fonts/helvetica/5cd6e481-b6d9-47fa-b568-1636d8a6cc52.ttf") format("truetype"),
		 url("../fonts/helvetica/0134ff8c-b1ae-47f0-9219-d1bdce3ec1db.svg#0134ff8c-b1ae-47f0-9219-d1bdce3ec1db") format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face { // MEDIUM OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/9e97b244-e222-4613-ab0b-a764773596a3.eot?#iefix");
	src: url("../fonts/helvetica/9e97b244-e222-4613-ab0b-a764773596a3.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/4ed8935f-c60a-4861-aa65-33dc913e917b.woff2") format("woff2"),
		 url("../fonts/helvetica/9604fe50-0916-42ef-aa87-db805fc07cf7.woff") format("woff"),
		 url("../fonts/helvetica/b59745b6-71dc-42e5-ab27-7b341eb6506b.ttf") format("truetype"),
		 url("../fonts/helvetica/968470de-55d3-4720-add7-7994bba95e9c.svg#968470de-55d3-4720-add7-7994bba95e9c") format("svg");
	font-weight: 500;
	font-style: italic;
}

@font-face { // BOLD
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/1527aeed-e69d-4399-82e3-7b53d48da7a2.eot?#iefix");
	src: url("../fonts/helvetica/1527aeed-e69d-4399-82e3-7b53d48da7a2.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/5f4620bc-98f7-4b13-9f90-3a54ea6a9c55.woff2") format("woff2"),
		 url("../fonts/helvetica/ac30e84e-cb19-4576-b6f9-491fccbd42ff.woff") format("woff"),
		 url("../fonts/helvetica/b583b782-0709-4a13-899f-7e9ab1a7eccd.ttf") format("truetype"),
		 url("../fonts/helvetica/d5dd03f5-3afb-46e9-aad0-234de8607afa.svg#d5dd03f5-3afb-46e9-aad0-234de8607afa") format("svg");
	font-weight: 600;
	font-style: normal;
}

@font-face { // BOLD OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/7560f08f-e627-461f-acba-c2fc820c6f0c.eot?#iefix");
	src: url("../fonts/helvetica/7560f08f-e627-461f-acba-c2fc820c6f0c.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/a2957f2d-9b3b-4aea-b86c-bba81fc8128e.woff2") format("woff2"),
		 url("../fonts/helvetica/879d541c-7f90-4fe6-93c4-453ee22fa15e.woff") format("woff"),
		 url("../fonts/helvetica/75f88dce-dde4-429c-92d1-1a1631b021a8.ttf") format("truetype"),
		 url("../fonts/helvetica/164f7c14-41fa-4682-9997-b8f0841b7666.svg#164f7c14-41fa-4682-9997-b8f0841b7666") format("svg");
	font-weight: 600;
	font-style: italic;
}

@font-face { // HEAVY
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/dfe2df2c-9e8e-489d-b572-0f24043e9ca1.eot?#iefix");
	src: url("../fonts/helvetica/dfe2df2c-9e8e-489d-b572-0f24043e9ca1.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/835c9867-49fd-49c6-ad61-f6e4e47e17fa.woff2") format("woff2"),
		 url("../fonts/helvetica/0c4dfc49-f798-458f-be90-e1d5a3e65f48.woff") format("woff"),
		 url("../fonts/helvetica/f4ad3cac-3a29-41f8-b890-e7badf717aa0.ttf") format("truetype"),
		 url("../fonts/helvetica/c1ce2e4a-f726-4fea-89b2-7e0896c07a06.svg#c1ce2e4a-f726-4fea-89b2-7e0896c07a06") format("svg");
	font-weight: 700;
	font-style: normal;
}

@font-face { // HEAVY OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/1a4c2095-2750-49bd-8f7d-cd6c592afd44.eot?#iefix");
	src: url("../fonts/helvetica/1a4c2095-2750-49bd-8f7d-cd6c592afd44.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/553e628a-a8fd-435f-bb45-83e721924cf9.woff2") format("woff2"),
		 url("../fonts/helvetica/2dc6275e-59c0-4e63-849f-4c0261be9817.woff") format("woff"),
		 url("../fonts/helvetica/07c1c0ff-91b8-4163-98a7-87c9ceea9ed5.ttf") format("truetype"),
		 url("../fonts/helvetica/e15c4e28-fd35-43bd-ba61-eaaa65e47dc7.svg#e15c4e28-fd35-43bd-ba61-eaaa65e47dc7") format("svg");
	font-weight: 700;
	font-style: italic;
}

@font-face { // BLACK
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/fb8ed62a-3750-405b-b721-f4b950bfd875.eot?#iefix");
	src: url("../fonts/helvetica/fb8ed62a-3750-405b-b721-f4b950bfd875.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/d38cc1e3-5680-4390-8f60-78901d6e375b.woff2") format("woff2"),
		 url("../fonts/helvetica/04d98c2f-b101-4363-976a-6581c5acf5d8.woff") format("woff"),
		 url("../fonts/helvetica/f20324bd-78cb-412c-a570-efea43e52715.ttf") format("truetype"),
		 url("../fonts/helvetica/b3a7788b-f2f7-453b-bf6a-66bd22632ef4.svg#b3a7788b-f2f7-453b-bf6a-66bd22632ef4") format("svg");
	font-weight: 800;
	font-style: normal;
}

@font-face { // BLACK OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/dcbadd81-448d-460b-afcc-9d3dd0d7a0f2.eot?#iefix");
	src: url("../fonts/helvetica/dcbadd81-448d-460b-afcc-9d3dd0d7a0f2.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/cbd423a2-b084-4db7-aeb1-e5419cbe11d1.woff2") format("woff2"),
		 url("../fonts/helvetica/4eb7368f-163e-4f0f-a3c5-4e4294788f84.woff") format("woff"),
		 url("../fonts/helvetica/8feab4b3-39f5-4762-adc8-8383d27da4b7.ttf") format("truetype"),
		 url("../fonts/helvetica/60912f66-c822-4eac-b509-a02945086035.svg#60912f66-c822-4eac-b509-a02945086035") format("svg");
	font-weight: 800;
	font-style: italic;
}

@font-face { // EXTRA BLACK
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/beeae2da-8ac8-47ed-9e87-4f9bdd1454fe.eot?#iefix");
	src: url("../fonts/helvetica/beeae2da-8ac8-47ed-9e87-4f9bdd1454fe.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/931ee3cf-83a1-4ca5-b091-97c58c39a850.woff2") format("woff2"),
		 url("../fonts/helvetica/e08521db-2fd8-4bc6-9294-0e563b179db9.woff") format("woff"),
		 url("../fonts/helvetica/2fe5a3aa-9a97-4012-91bc-f5a5515b27ad.ttf") format("truetype"),
		 url("../fonts/helvetica/50a9d8b8-1d65-417c-a3fe-37a19246b43f.svg#50a9d8b8-1d65-417c-a3fe-37a19246b43f") format("svg");
	font-weight: 900;
	font-style: normal;
}

@font-face { // EXTRA BLACK OBLIQUE
	font-family: "Helvetica Neue Condensed";
	src: url("../fonts/helvetica/36e5b7d5-3931-4842-9735-0fc3daffbcba.eot?#iefix");
	src: url("../fonts/helvetica/36e5b7d5-3931-4842-9735-0fc3daffbcba.eot?#iefix") format("eot"),
		 url("../fonts/helvetica/2d0587fa-064d-4831-9f0d-d559226a8588.woff2") format("woff2"),
		 url("../fonts/helvetica/c7e5065a-194f-48d7-a1f5-4018044110d3.woff") format("woff"),
		 url("../fonts/helvetica/4151fc85-8bcd-4ee2-9d52-2a9e3ef7ef29.ttf") format("truetype"),
		 url("../fonts/helvetica/2700b1dd-d3b2-4a3b-bac3-fb9351fa6c84.svg#2700b1dd-d3b2-4a3b-bac3-fb9351fa6c84") format("svg");
	font-weight: 900;
	font-style: italic;
}


/* -- ENPC FONT ICONS ------------------------------------------------------------ */

@font-face {
	font-family: "enpc";
	src: url("../fonts/enpc/enpc.eot");
	src: url("../fonts/enpc/enpc.eot?#iefix") format("embedded-opentype"),
		 url("../fonts/enpc/enpc.woff") format("woff"),
		 url("../fonts/enpc/enpc.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

[data-icon]:before {
	display: block;
	font-family: "enpc" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	display: block;
	font-family: "enpc" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
	content: "\61";
}
.icon-facebook:before {
	content: "\62";
}
.icon-twitter:before {
	content: "\63";
}
.icon-youtube:before {
	content: "\64";
}
.icon-dailymotion:before {
	content: "\65";
}
.icon-date:before {
	content: "\66";
}
.icon-arrow-bottom:before {
	content: "\67";
}
.icon-arrow-left:before {
	content: "\68";
}
.icon-arrow-right:before {
	content: "\69";
}
.icon-arrow-top:before {
	content: "\6a";
}
.icon-share:before {
	content: "\6b";
}
.icon-arrow-bottom-bold:before {
	content: "\6c";
}
.icon-book:before {
	content: "\6d";
}
.icon-player:before {
	content: "\6e";
}
.icon-enpc:before {
	content: "\6f";
}
.icon-linkedin:before {
	content: "\70";
}
.icon-pdf:before {
	content: "\71";
}
.icon-image:before {
	content: "\72";
}
.icon-excel:before {
	content: "\73";
}
.icon-ppoint:before {
	content: "\74";
}
.icon-doc:before {
	content: "\75";
}
.icon-archive:before {
	content: "\76";
}
.icon-page:before {
	content: "\77";
}
.icon-burger:before {
	content: "\78";
}
.icon-print:before {
	content: "\79";
}